<template>
  <div class="home">
    <div class="top-banner">
      <div class="container py-0">
        <ul class="nav_testament" ref="tuto-highlight-nav-testament">
          <li v-for="(testament, index) in this.testaments" @click="updateCurrentTestament(index)"
              :class="testament.indexStart === currentTestament.indexStart ? 'active' : 'inactive'">{{ testament.name }}
          </li>
        </ul>
      </div>
    </div>

    <div class="container pt-18" v-if="!isLoading">
      <div v-for="(grouping, index) in this.groupingBooksTestament" class="mb-8">
        <p class="title-2-merriweather mb-6">{{ getTestamentGrouping(index)[locale] }}</p>
        <div class="d-flex flex-wrap" :class="index === 0 ? 'tuto-highlight-nav-books' : ''">
          <router-link :to="{name: 'book', params: {bookAbbreviation: book.url_param}}" @click.native="nextPageClick()" v-for="book in grouping" class="book_description"  :style="'--color-underline-book:'+getColorBook(book.url_param)" >
            <div>
              <p class="titre">{{ book.name }}</p>
              <p v-if="getSyntheticNote(book.abbreviation)">{{ getSyntheticNote(book.abbreviation).text }}</p>
            </div>
          </router-link>
        </div>
      </div>
    </div>
    <p v-else>
      {{ $t('loading') }}
    </p>
  </div>

</template>

<script>
import BestApiService from "../services/BestApiService";
import CookieManager from "../classes/CookieManager";
import { useShepherd } from 'vue-shepherd';

export default {
  //beforeDestroy() {
//    window.$vueEventBus.$off();
//  },
  computed: {
    testaments: function () {
      return this.toc ? this.toc.parts : [];
    },
    currentTestament() {
      return this.testaments[this.testamentId];
    },
    groupingBooksTestament: function () {
      if (!this.currentTestament || !this.toc) {
        return []
      }

      return this.currentTestament.groupings.map((grouping) => {
        return this.toc.books.filter(book => grouping?.books.includes(book.abbreviation))
      });
    },
    books: function () {
      return this.toc ? this.toc.books : [];
    },
  }, data() {
    return {
      toc: null,
      introductions: [],
      testamentId: 0,
      testamentsGrouping: this.$store.state.testamentsGrouping,
      bookColors: this.$store.state.bookColors,
      isLoading: true,
      locale: 'fr',
      cookieManager: new CookieManager(),
      tour: useShepherd({useModalOverlay: true,
        defaultStepOptions: {
          scrollTo: { behavior: 'smooth', block: 'center' }
        },
      })
    }
  }, mounted() {
    this.fetchContent();
    window.$vueEventBus.$on('locale_change', this.fetchContent);
    window.$vueEventBus.$on('user_change', () => {
      this.fetchContent(true)
    });
    window.$vueEventBus.$on('relaunchTuto', () => {
      this.initTuto(true)
    })

  }, methods: {
    fetchContent(wantReload = false) {
      BestApiService.getToc(this.$store, wantReload, (json) => {
        this.toc = json;
        let testamentName = this.$route.query ? this.$route.query.testament : null;
        this.testamentId = testamentName ? this.toc.parts.findIndex(testament => testament.name === testamentName) : 0;
        this.$forceUpdate();
      });

      BestApiService.getIntroductions(this.$store, wantReload, () => {
        this.introductions = this.$store.getters.getIntroductions().map((intro) => {
          let abbr = intro.scope.abbr.split(' ')[0];
          return {
            id: intro.id,
            bookAbbreviation: abbr,
            text : this.getSmartIntro(intro.text)
          }
        });

        this.isLoading = false;
        setTimeout(() => {
          this.initTuto()
        }, 100)

        this.refreshTitle();
        this.$forceUpdate();
      });
      this.locale = this.$store.getters.getLocale();
    }, updateCurrentTestament(testamentId) {
      this.testamentId = testamentId;
      this.refreshTitle();

      this.nextStepTuto('testament-step')
    }, nextPageClick(event) {
      this.nextStepTuto('book-step');
    }, getSyntheticNote(bookAbbreviation) {
      if(!this.introductions) {
        return null;
      }
      return this.introductions.find((intro) => intro.bookAbbreviation === bookAbbreviation)
    }, getSmartIntro(introHtml) {
      let parser = new DOMParser();
      let doc = parser.parseFromString(introHtml, 'text/html');
      let firstParagraphElement = doc.body.querySelector('p') ? doc.body.querySelector('p').innerText : '';
      return firstParagraphElement.slice(0, 250) + (firstParagraphElement.length > 250 ? '...' : '');
    }, getColorBook(bookAbbreviation) {
      let group = this.$store.getters.getColorBook(bookAbbreviation);
      return group?.color;
    }, getTestamentGrouping(index) {
      if(this.currentTestament.indexStart === 0) {
        return this.$store.getters.getTestamentsGrouping()[0][index];
      }
      return this.$store.getters.getTestamentsGrouping()[1][index];
    }, refreshTitle() {
      $(document).prop('title', this.currentTestament ? this.currentTestament.name : 'La Bible en ses Traditions');
    }, initTuto(fromEvent = false) {
      if(this.cookieManager.getCookie('tutoHomeVisited', false)) {
        return;
      }
      this.tour.addSteps([
          {
            title: this.$t('tuto.welcome.title'),
            text : this.$t('tuto.welcome.content'),
            buttons: [
              {
                action() {
                  return this.cancel();
                },
                text: this.$t('tuto.button.close'),
              },
              {
                action() {
                  return this.next();
                },
                text: this.$t('tuto.button.next'),
              }
            ],
          },
          {
            text : this.$t('tuto.testament.content'),
            attachTo: { element: this.$refs["tuto-highlight-nav-testament"], on: 'bottom-start' },
            classes: 'mt-4 ml-3 arrow-start',
            modalOverlayOpeningPadding: 10,
            modalOverlayOpeningRadius: 5,
            buttons: [
              {
                action() {
                  return this.cancel();
                },
                text: this.$t('tuto.button.close'),
              },
            ],
            test: 'test',
            id: 'testament-step',
          },
          {
            text : this.$t('tuto.book_list.content'),
            classes: 'mt-4',
            modalOverlayOpeningPadding: 10,
            modalOverlayOpeningRadius: 5,
            attachTo: { element: document.querySelector('.tuto-highlight-nav-books'), on: 'bottom'},
            buttons: [
              {
                action() {
                  return this.cancel();
                },
                text: this.$t('tuto.button.close'),
              },
              {
                action() {
                  return this.back();
                },
                text: this.$t('tuto.button.other_testament'),
              },
            ],
            id: 'book-step',
          },
        ])

      const urlParams = new URLSearchParams(window.location.search);
      if (!urlParams.get('from') || fromEvent) {
        this.tour.start();

        ['cancel'].forEach(eventName => {
          this.tour.on(eventName, event => {
            this.cookieManager.setCookie('tutoHomeVisited', true);
            this.cookieManager.setCookie('sommaireBibleVisited', true);
            this.cookieManager.setCookie('bibleVisited', true);
          })
        })

            ['complete'].forEach(eventName => {
          this.tour.on(eventName, event => {
            this.cookieManager.setCookie('tutoHomeVisited', true);
          })
        })
      }
    }, nextStepTuto(stepId) {
      if(this.tour && this.tour.getCurrentStep() &&  this.tour.getCurrentStep().id === stepId){
        this.tour.next()
      }
    }
  }
}
</script>

<style>
.top-banner {
  background: #E8E9EC;
  padding-top: 25px;
}

.nav_testament {
  display: flex;
  list-style-type: none;
  padding: 20px 0 0 0 !important;
  margin: 0;
}

.nav_testament li {
  color: #1B2847;
  font-size: 20px;
  line-height: 25px;
  font-family: 'Merriweather', serif;
  font-weight: bold;
  text-decoration: none;
  display: block;
  position: relative;
  cursor: pointer;
  padding-bottom: 18px;
  transition: 0.3s;
  text-align: center;
}

@media (max-width: 700px) {
  .nav_testament li {
    font-size: 17px;
    line-height: 23px;
  }
}

.nav_testament li.active:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
  width: 80px;
  height: 8px;
  background-color: #1B2847;
}

.nav_testament li.inactive {
  opacity: 0.33;
}

.nav_testament li.inactive:hover,
.nav_testament li.inactive:focus,
.nav_testament li.inactive:active {
  opacity: 1;
}

.nav_testament li:not(:last-child) {
  margin-right: 48px;
}

/* POUR LA DEMO */

.book_description:before {
  background-color: var(--color-underline-book) !important;
}

</style>
