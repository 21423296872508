<template>
  <div>
    <div class="custom-modal draggable-modal" :style="{'z-index' : modalContent.customZIndex , top: (30 + modalContent.customIndex) + '%', left: (30 + modalContent.customIndex) + '%', display: modalContent.display ? 'flex' : 'none'}" ref="draggable-modal">
      <div ref="draggable-modal-content">
        <div class="header">
          <div v-if="modalContent.title" class="modal-title">
            <div v-html="$t(modalContent.title)"></div>
            <div v-if="modalContent instanceof VerseModalContentClass && Array.isArray(modalContent.content)">
              <router-link :to="{name: 'bible', params: {bookAbbreviation:getPartUrlFromScopeUrlParam(modalContent.content[0]).abbr, chapter: getPartUrlFromScopeUrlParam(modalContent.content[0]).chapter}, query: {verse: getPartUrlFromScopeUrlParam(modalContent.content[0]).verse}}" v-if="modalContent.content[0]">
                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-arrow-narrow-right" width="28" height="28" viewBox="0 0 24 24" stroke-width="1.5" stroke="#000000" fill="none" stroke-linecap="round" stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                  <path d="M5 12l14 0" />
                  <path d="M15 16l4 -4" />
                  <path d="M15 8l4 4" />
                </svg>
              </router-link>
            </div>
          </div>
          <div @click="closeModal">
            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-x" width="28" height="28" viewBox="0 0 24 24" stroke-width="1.5" stroke="#000000" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M18 6l-12 12" /><path d="M6 6l12 12" /></svg>
          </div>
        </div>
        <div class="content">
          <div v-if="modalContent instanceof VerseModalContentClass && Array.isArray(modalContent.content)">
            <Verse
                v-for="verse in modalContent.content"
                :currentChapter="modalContent.chapter"
                :verse="verse"
                :mainTradition="mainTradition"
            ></Verse>
          </div>
          <div class="note-content" v-else-if="modalContent.content">
            <div class="text-content" v-html="$t(modalContent.content)"></div>
          </div>
          <div v-else class="text-content" >
            <p>{{ $t('note_loading') }}</p>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import ModalContentClass from "../classes/ModalContentClass";
import {gsap} from "gsap";
import {Draggable} from "gsap/Draggable";
import Verse from "./Verse.vue";
import VerseModalContentClass from "../classes/VerseModalContentClass";
export default {
  components: {Verse},
  data() {
    return {
      draggable : null,
    }
  },
  computed: {
    VerseModalContentClass() {
      return VerseModalContentClass
    }
  },
  methods: {
    closeModal() {
      window.$vueEventBus.$emit('close_reference_modal', this.modalContent)
    },
    getPartUrlFromScopeUrlParam(verse) {
      let urlParam = verse.scope.url_param;
      let regex = new RegExp('([0-9.]*$)');
      let partBook = regex.exec(urlParam)[0];

      return {
        abbr: urlParam.replace(partBook, ''),
        chapter: partBook.substring(0, partBook.indexOf(".")),
        verse: partBook.substring(partBook.indexOf(".") + 1)
      }
    },
  },
  mounted() {
    gsap.registerPlugin(Draggable);
    this.draggable = Draggable.create(this.$refs["draggable-modal"], {
      type : 'x, y',
      zIndexBoost : false,
      trigger: this.$refs["draggable-modal-content"],
      onDragStart: function () {
        let allModals = Array.from(document.querySelectorAll('.draggable-modal'))
        allModals.sort((el1, el2) => (parseInt(el1.style['z-index']) < parseInt(el2.style['z-index']) ? 1 : -1));
        let lastZindex = allModals[0] ? parseInt(allModals[0].style['z-index']) : 900;
        this.target.style['z-index'] = lastZindex + 1
      },
    })[0]
  },
  name: 'DraggableModal',
  props: {
    modalContent: {
      type: ModalContentClass,
      default: () => {
        return null
      }
    },
    mainTradition: {
      type: String,
      default: () => {
        return 'V'
      }
    },
  },
}
</script>

<style>
.draggable-modal {
  background: white;
  position: fixed;
  width: max(50vw,500px);
  //max-width: min(90vw, 800px);
  height: max(40vh, 500px);
  //max-height: 70vh;
  min-width: 300px;
  border: black 3px solid;
  display:flex;
  flex-direction: column;
  resize: both;
  overflow: auto;
  scrollbar-width: thin; /* "auto" or "thin"  */
  scrollbar-color: #1B2847 #E6E7EB; /* scroll thumb & track */
  .header{
    position: sticky;
    top: 0;
    background: #ffffff;
    z-index: 1;
  }
  .title {
    flex-shrink: 0;
  }
  .content {
    overflow-y: auto;
    img {
      max-width: 100%;
      margin: 20px;
    }
    p{
      font-size: 14px;
    }
  }
}
.draggable-modal::-webkit-scrollbar {
  width: 12px; /* width of the entire scrollbar */
}
.draggable-modal::-webkit-scrollbar-track {
  background: #E6E7EB; /* color of the tracking area */
}
.draggable-modal::-webkit-scrollbar-thumb {
  background-color: #1B2847; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  border: 3px solid #E6E7EB; /* creates padding around scroll thumb */
}
</style>