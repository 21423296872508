<template>
  <!-- TODO : Ajouter plutôt la classe "verse-note-open" dans le cas de : isActiveNotesVerse -->
  <div
    class="verse"
    :class="[
      ...verseStatus(isActiveNotesVerse, traductionConfiguration),
      highlighted && 'verse-note-highlighted'
    ]"
  >
    <div class="traditions d-flex justify-content-end pr-5 pt-1">
        <span class="tradition-badge badge-color-hover mr-1 mb-1" v-for="tradition in versesByTradition.mainVerseTradition.traditions"
            :title="getTraditionObject(tradition).name[locale]"
            :style="`--color-badge:${getTraditionObject(tradition).color}`"
            :class="mainTradition === tradition ? 'active' : 'inactive'">{{
          tradition
        }}</span>
    </div>

    <div class="verse_metadata">
      <span class="verse_number">{{ verseNumber }}</span>
    </div>

    <div class="verse_content" v-if="versesByTradition">
      <div v-html="versesByTradition.mainVerseTradition.verse"></div>
      <div class="other-traditions" v-if="versesByTradition.otherVerseTradition.length">
        <div class="other-traditions-content">
          <div class="tradition-wrapper" v-for="otherTraditionVerse in versesByTradition.otherVerseTradition">
            <div class="traditions">
              <span class="tradition-badge badge-color-hover mr-1" v-for="tradition in otherTraditionVerse.traditions"
                  :style="`--color-badge:${getTraditionObject(tradition).color}`"
                  :title="getTraditionObject(tradition).name[locale]"
                  :class="mainTradition === tradition ? 'active' : 'inactive'">{{
                tradition
              }}</span>
            </div>
            <div v-html="otherTraditionVerse.verse"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import index from "vuex";
import VerseTraditionGroup from "../classes/Verse/VerseTraditionGroup";
import {difference, cloneDeep} from "lodash";

export default {
  data() {
    return {
      verseNumber : null,
    }
  },
  computed: {
    activesTraditions: function() {
      return this.$store.getters.getActivesTraditions();
    },
    traditions: function() {
      return this.$store.getters.getAllTraditions();
    },
    traductionConfiguration: function () {
      return this.$store.getters.getTraductionConfiguration();
    },
    versesByTradition: function () {
      const domParser = new DOMParser();
      let text = cloneDeep(this.verse.text)
      let element = domParser.parseFromString(text, 'text/html');

      // SET le numéro du verse
      this.verseNumber = element.querySelector('span.verse_number').innerText.replace(' ', '');

      let traditionGroups = this.verse['textual-traditions'];
      let verseTraditionsGroupsDiv = element.body.querySelectorAll('div.witness');

      let traditionsWithVerses = [];
      let filterActiveTraditionsGroups = [];

      // On filtre les traditions par celles qui sont actives
      traditionGroups.forEach((traditions, index) => {
        let traditionsByActive = this.filterTraditionsByActive(traditions);

        if(traditionsByActive.length) {
          filterActiveTraditionsGroups = [...filterActiveTraditionsGroups, traditionsByActive]
          traditionsWithVerses = [...traditionsWithVerses, new VerseTraditionGroup(traditionsByActive, verseTraditionsGroupsDiv[index])];
        }
      })

      let verseTraditionsGroups = Array.from(traditionsWithVerses).map((verseTraditionGroup) => {
        let verseLines = verseTraditionGroup.htmlVerse.querySelectorAll('.verse_part');

        Array.from(verseLines).forEach((verseLine, indexP) => {
          let versePartsText = verseLine.querySelectorAll('span.variant,span.non-variant-text,span.divinename');

          let textToReinsert = this.getReformattedParagraphSpan(versePartsText, verseTraditionGroup, verseLines, indexP, verseLine);

          if (['.', '. ', ' '].includes(verseLine.innerText)) {
            verseLine.remove();
          }

          if(textToReinsert !== '') {
            verseLine.innerHTML = textToReinsert;
          }

          if(this.currentNote && this.currentNote.lemma) {
            let verseLineForCompareLemma = verseLine.cloneNode(true);
            verseLineForCompareLemma.querySelectorAll('span.tradition-badge').forEach(badge => badge.remove())

            if(verseLineForCompareLemma.innerHTML.includes(this.currentNote.lemma)) {
              verseLine.innerHTML = verseLine.outerHTML.replace(this.currentNote.lemma, `<span class="note-highlight">${this.currentNote.lemma}</span>`)
            }
          }
        })
        return {
          verse: verseTraditionGroup.htmlVerse.outerHTML,
          traditions: verseTraditionGroup.traditions,
        };
      });

      let columnForMainTradition = filterActiveTraditionsGroups.findIndex(traditions => traditions.indexOf(this.mainTradition) !== -1);

      if(columnForMainTradition < 0) {
        columnForMainTradition = 0;
      }
      let mainVerseTradition = verseTraditionsGroups[columnForMainTradition];

      verseTraditionsGroups.splice(columnForMainTradition, 1)
      return {
        mainVerseTradition: mainVerseTradition,
        otherVerseTradition: verseTraditionsGroups,
      }
    },
  },
  methods: {
    getTraditionObject(traditionAbbreviation) {
      return this.traditions[traditionAbbreviation]?? this.traditions.default;
    },
    filterTraditionsByActive(traditions) {
      return traditions.filter(tradition => this.activesTraditions.includes(tradition))
    },
    addVariantSpan(textWithVariant, traditionsToMergeInSpan, activeGroupTraditionsFromVerse) {
      // Quand on filtre les traditions, certaines sont des variantes alors on les rend non variantes
      if(traditionsToMergeInSpan.length === 0) {
        return textWithVariant ? textWithVariant.innerHTML : ' ';
      }

      // Si il reste une variante qui et que ses traduction sont la seule du verse, on affiche directement la vairante
      if(traditionsToMergeInSpan.length === 1 && activeGroupTraditionsFromVerse.filter(tradition => !traditionsToMergeInSpan[0].traditions.includes(tradition)).length === 0) {
        return traditionsToMergeInSpan[0].htmlVerse.innerHTML
      }

      // On met la vulgate comme texte principal
      let vulgateVariant = traditionsToMergeInSpan.find(traditionToMerge => traditionToMerge.traditions.includes('V'));

      if(vulgateVariant) {
        traditionsToMergeInSpan = traditionsToMergeInSpan.filter(traditionToMerge => !traditionToMerge.traditions.includes('V'));
        let traditionsInOtherVariants = traditionsToMergeInSpan.map(traditionToMerge => traditionToMerge.traditions).flat(1);
        let traditionsForNewVariants = difference(activeGroupTraditionsFromVerse, traditionsInOtherVariants).filter(tradition => tradition !== 'V')

        traditionsToMergeInSpan = [...traditionsToMergeInSpan, new VerseTraditionGroup(traditionsForNewVariants, textWithVariant)];
        textWithVariant = vulgateVariant.htmlVerse;
      }

      let variant_return = '<span class="text-with-variant">';

      if(textWithVariant) {
        variant_return += '<span class="base-text">'+textWithVariant.innerHTML+'</span> <span class="text-variant">'
      } else {
        variant_return += '<span class="base-text elipsis-text">'
      }

      traditionsToMergeInSpan.forEach(traditionToMergeInSpan => {
        let traditionsForVariant = traditionToMergeInSpan.traditions.map(tradition => `<span class="tradition-badge badge-color-hover" title="${this.getTraditionObject(tradition).name[this.locale]}" style="--color-badge:${this.getTraditionObject(tradition)?.color};">${tradition}</span>`)
        variant_return += `<span class="tmr-1">${traditionsForVariant.join('')}</span><span class="text">${traditionToMergeInSpan.htmlVerse ? traditionToMergeInSpan.htmlVerse.innerHTML : ' '}</span>`
      })

      variant_return += '</span></span>';
      return variant_return;
    },
    verseStatus(isActiveNotesVerse, traductionConfiguration) {
      let verseClassList = '';
      if(isActiveNotesVerse) {
        verseClassList += 'verse-note-open ';
      }
      if(traductionConfiguration != 'close') {
        verseClassList += 'active-notes ';
      }
      return verseClassList;
    },
    getReformattedParagraphSpan(spanArray, verseTraditionGroup, verseLines, indexP, verseLine) {
      let textToReinsert = '';
      Array.from(spanArray).forEach((span) => {
        // Les variantes doivent récupérer l'autre variante du paragraphe suivant pour s'imbriquer et s'afficher l'un en dessous de l'autre
        if (span.classList.contains('variant')) {
          let variantTradition = span.querySelector('.text_trad');

          // Si la span n'a pas la classe "variant_n" et a un enfant avec class "text_trad" alors on affiche en entière
          if (!span.classList.contains('variant_n') && variantTradition) {
            let traditions = variantTradition.innerText.split(' ');
            let traditionsByActive = this.filterTraditionsByActive(traditions);

            if(traditionsByActive.length) {
              let spanTextVariant = span.querySelector('.variant-text')
              textToReinsert += this.addVariantSpan(null, [new VerseTraditionGroup(traditionsByActive, spanTextVariant)], verseTraditionGroup.traditions);
            }
          } else {
            let index = 1;
            let traditionsToMergeInSpan = [];
            let spanTextWithVariant = span.querySelector('.variant-text');
            while (verseLines[indexP + index] && verseLines[indexP + index].firstChild && verseLines[indexP + index].firstChild.classList.contains('variant_n')) {
              let variantTextFromNextP = verseLines[indexP + index].firstChild;

              let traditionsByActive = []
              if (variantTextFromNextP.querySelector('.text_trad')) {
                let traditions = variantTextFromNextP.querySelector('.text_trad').innerText.split(' ');
                traditionsByActive = this.filterTraditionsByActive(traditions);
              }

              if(traditionsByActive.length) {
                let spanTextVariant = variantTextFromNextP.querySelector('.variant-text')
                traditionsToMergeInSpan = [...traditionsToMergeInSpan, new VerseTraditionGroup(traditionsByActive, spanTextVariant)]
              }

              variantTextFromNextP.remove();

              if(verseLines[indexP + index].querySelectorAll('span.variant,span.non-variant-text,span.divinename').length > 0) {
                break;
              }
              index +=1
            }

            let textToInsertVariant = this.addVariantSpan(spanTextWithVariant, traditionsToMergeInSpan, verseTraditionGroup.traditions);
            if(textToInsertVariant.includes('<span class="text-variant">')) {
              verseLine.classList.add('contains-variant')
            }
            textToReinsert += textToInsertVariant;

            if(verseLines[indexP + index]) {
              let subSpans = verseLines[indexP + index].querySelectorAll('span.variant,span.non-variant-text,span.divinename');
              if(subSpans) {
                textToReinsert += this.getReformattedParagraphSpan(subSpans, verseTraditionGroup, verseLines, indexP + index, verseLine)
                subSpans.forEach(el => {
                  el.remove();
                })
              }
            }
          }
        } else {
          textToReinsert += span.outerHTML
        }
      })
      return textToReinsert;
    }
  },
  props: {
    verse: {
      type: Object,
      default: () => {
        return {}
      }
    },
    currentChapter: {
      type: String|Number,
      default: () => {
        return 1
      }
    },
    isActiveNotesVerse: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    currentNote: {
      type: Object,
      default: () => {
        return null
      }
    },
    mainTradition: {
      type: String,
      default: () => {
        return 'V'
      }
    },
    lastVerseFromChapter: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    locale: {
      type: String,
      default: () => {
        return 'fr'
      }
    },
    highlighted: {
      type: Boolean,
      default: () => {
        return false
      }
    }
  },
  name: 'Verse',
}
</script>

<style>
.verse_content p,
.verse_content div,
.verse_content span,
.verse_content p .non-variant-text {
  font-size: 16px;
  line-height: 22px;
  font-family: 'Merriweather', serif;
  margin-bottom: 0 !important;
}

.verse_content span.divinename, span.divine-name {
  font-variant-caps: small-caps;
}

.note-highlight {
  color: #b40000;
}

</style>

<style scoped>

* {
  font-family: 'Arimo', sans-serif;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

img {
  width: 100%;
  max-width: 100%;
}

@media (min-width: 900px) {
  .verse {
    position: relative;
    margin-bottom: 4px;
    transition: 0.3s ease-in-out;
    padding: 11px 102px 0 35px;
    display: grid;
    grid-template-columns: 165px 32px 1fr;
  }

  .verse:hover,
  .verse.verse-note-open,
  .verse.verse-note-highlighted {
    background-color: #E5ECF5;
  }

  .verse.verse-note-highlighted:not(.verse-note-open) {
    padding-bottom: 11px;
  }

  .verse:hover,
  .verse.verse-note-open,
  .verse.active-notes {
    padding-bottom: 23px;
    border-radius: 6px;
    margin-bottom: 8px;
    cursor: pointer;
  }

  .verse.verse-note-open:after {
    content: '';
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    height: 8px;
    width: 8px;
    background-color: #1B2847;
    border-radius: 20px;
  }
}

@media (max-width: 900px) {
  .verse {
    padding: 0 40px;
    position: relative;
    margin-bottom: 24px;
  }

  .verse .traditions {
    display: block !important;
  }

  .verse .verse_number {
    position: absolute;
    left: 0;
    top: 11px;
    width: 24px;
    text-align: center;
    padding-top: 8px;
    border-top: 2px solid #B40000;
    font-size: 14px;
    line-height: 16px;
  }

  .verse .tradition-badge {
    font-size: 14px !important;
    line-height: 18px !important;
    background: transparent;
    display: inline;
    min-width: unset;
  }

}

.verse_content >>> .verse_number,
.verse_content >>> .text_trad {
  display: none;
}

.verse_number {
  color: #B40000;
  font-size: 12px;
  line-height: 14px;
  font-weight: 700;
  text-decoration: none;
}

.theme--dark .verse-number {
  color: #c71b1b !important;
}

.notes_amount {
  position: absolute;
  top: 46px;
  text-align: center;
  width: 100%;
  color: #726B55;
  font-size: 12px;
  line-height: 12px;
}

.theme--dark .notes_amount {
  color: #b7b6b2;
}

.verse_notes {
  display: block;
}

.notes_label {
  display: block;
  text-decoration: underline;
}

</style>
