<template>
  <v-app-bar app>
    <div class="d-flex justify-content-between align-items-center w-100">
      <div class="d-flex justify-content-between align-items-center">
        <div class="mr-4">
          <v-app-bar-nav-icon>
            <router-link  :to="{name: 'home'}">
              <img class="logo light-theme-item" src="/images/logo-black.png" :title="$t('home_redirect')" data-toggle="tooltip" data-placement="bottom" alt="Logo" />
              <img class="logo dark-theme-item" src="/images/logo-white.png" :title="$t('home_redirect')" data-toggle="tooltip" data-placement="bottom" alt="Logo" />
            </router-link>
          </v-app-bar-nav-icon>
        </div>
        <v-toolbar-title>
          <a href="https://www.bibletraditions.org/" class="main-navigation-router" :title="$t('bibletradition_redirect')" data-toggle="tooltip" data-placement="bottom">
            <span class="bible">{{ $t('bible') }}</span><br />{{ $t('traditions') }}
          </a>
        </v-toolbar-title>
      </div>

      <div class="d-flex justify-content-between align-items-center">
        <div class="search mr-6" :class="[token ? '' : 'search-no-access']">
          <Search :user="user" :token="token" />
        </div>

        <div class="open-mobile-menu">
          <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-menu-deep" width="28" height="28" viewBox="0 0 24 24" stroke-width="1.5" stroke="#C91B23" fill="none" stroke-linecap="round" stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
            <path d="M4 6h16" />
            <path d="M7 12h13" />
            <path d="M10 18h10" />
          </svg>
        </div>
        <div class="custom-mobile-menu">
          <div class="locale-switcher mr-4">
            <svg xmlns="http://www.w3.org/2000/svg" class="chevron" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="#1B2847" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M6 9l6 6l6 -6" /></svg>
            <select @change="changeLocale($event)">
              <option value="fr" :selected="$i18n.locale == 'fr' ? 'selected' : ''">Fr.</option>
              <option value="en" :selected="$i18n.locale == 'en' ? 'selected' : ''">En.</option>
            </select>
          </div>

          <div class="tuto-highlight-login d-flex">
            <Login :user="user" :token="token" />
            <div v-if="!token"><Registration :user="user" :token="token" /></div>
          </div>
          <div>
            <v-menu v-model="donationMenu" :close-on-content-click="false" offset-x>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" class="donation-btn btn-red full mr-3 tuto-highlight-donation" :title="$t('make a donation')" style="padding:13px 16px !important" data-toggle="tooltip" data-placement="bottom">
                  <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-heart" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="#fff" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                    <path d="M19.5 12.572l-7.5 7.428l-7.5 -7.428a5 5 0 1 1 7.5 -6.566a5 5 0 1 1 7.5 6.572" />
                  </svg>
                </v-btn>
              </template>
              <template>
                <v-tabs class="elevation-2 panel-register modal-donation">
                  <v-tabs-slider></v-tabs-slider>
                  <v-tab href="#tab-donation" class="tab-element">
                    {{ $t('make a donation') }}
                  </v-tab>
                  <v-tab-item value="tab-donation">
                    <v-card flat tile>
                      <div class="close-modal close-donation-modal"><svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-x" width="28" height="28" viewBox="0 0 24 24" stroke-width="1.5" stroke="#000000" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M18 6l-12 12" /><path d="M6 6l12 12" /></svg></div>
                      <v-card-text>
                        <div>
                          <div class="donation-content">
                            <div>
                              <h2 class="text-center">{{ $t('donation_title') }}</h2>
                              <div class="d-flex justify-content-center">
                                <a class="btn-red full mb-5" href="https://don.fondationdesmonasteres.org/soutenir-Bible-en-ses-Traditions" style="display: inline-flex;" target="_blank">{{ $t('make_donation') }}</a>
                              </div>
                              <div v-html="$t('donation_content')">
                              </div>
                            </div>
                          </div>
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                </v-tabs>
              </template>
            </v-menu>
          </div>
          <div>
            <v-btn class="tuto-btn btn-red mr-3" :title="$t('see tuto')" @click="relaunchTuto" data-toggle="tooltip" data-placement="bottom">
              <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-question-mark" width="30" height="30" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <path d="M8 8a3.5 3 0 0 1 3.5 -3h1a3.5 3 0 0 1 3.5 3a3 3 0 0 1 -2 3a3 4 0 0 0 -2 4" />
                <path d="M12 19l0 .01" />
              </svg>
            </v-btn>
          </div>
        </div>
      </div>
    </div>
  </v-app-bar>
</template>

<script>
import Login from './Login';
import Search from './Search';
import Registration from "./Registration.vue";
import BestApiService from "../services/BestApiService";
import CookieManager from "../classes/CookieManager";

export default {
  components: { Login, Search, Registration },
  data () {
    return {
      token: '',
      user: {},
      donationMenu: false,
      cookieManager: new CookieManager(),
    }
  },
  mounted () {
    this.token = this.$store.getters.getToken();
    $('[data-toggle="tooltip"]').tooltip()
    this.fetchContent();
    window.$vueEventBus.$on('user_change', () => {
      this.token = this.$store.getters.getToken();
      this.user = this.$store.getters.getUser();
      this.fetchContent();
      this.$forceUpdate();
    });
  },
  methods: {
    fetchContent() {
      if (this.token && !this.user) {
        BestApiService.fetchUser()
            .then(json => {
              if (json.status == 'success') {
                this.user = json.user;
                this.$store.commit('SET_USER', this.user);
                this.$store.commit('SET_API_TOKEN', json.token_content);
              }
            });
      }
      this.$forceUpdate();
    },
    changeLocale(event) {
      let locale = null;
      switch (event.target.value) {
        case 'en' : locale = 'en';
          break;
        case 'es' : locale = 'es';
          break;
        default : locale = 'fr'
      };
      this.$i18n.locale = locale;
      this.$store.commit('SET_LOCALE', locale);
    },
    relaunchTuto() {
      if(this.$route.name === 'home') {
        this.cookieManager.setCookie('tutoHomeVisited', false);
        this.cookieManager.setCookie('bibleVisited', false);
        this.cookieManager.setCookie('sommaireBibleVisited', false);
      }
      if(this.$route.name === 'book') {
        this.cookieManager.setCookie('sommaireBibleVisited', false);
        this.cookieManager.setCookie('bibleVisited', false);
      }
      if(this.$route.name === 'bible') {
        this.cookieManager.setCookie('bibleVisited', false);
      }
      window.$vueEventBus.$emit('relaunchTuto')
    },
    toggleDarkMode() { this.$vuetify.theme.dark = !this.$vuetify.theme.dark },
  },
}
</script>

<style>

  * {
    font-family: 'Arimo', sans-serif;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }

  .v-toolbar {
    box-shadow: none !important;
    border-bottom: 2px solid #1B2847 !important;
    background-color: white !important;
  }

  .v-toolbar__content {
    padding: 21px 18px 11px 18px;
    height: 85px !important;
    position: relative;
  }

  .v-toolbar__content:before {
    content: '';
    position: absolute;
    top: 0;
    height: 13px;
    left: 0;
    right: 0;
    background-image: url("/images/bandeau-bible-art.png");
    background-size: cover;
    background-position: bottom center;
  }

  .v-toolbar__content>.v-btn.v-btn--icon:first-child+.v-toolbar__title {
    padding-right: 12px;
    padding-left: 12px;
  }

  .mdi-menu {
    color: #1B2847 !important;
    font-size: 28px !important;
  }

  .theme--dark .mdi-menu {
    color: white !important;
  }

  .mdi-magnify {
    transform: rotate(90deg) !important;
    color: black !important;
    font-size: 30px !important;
  }

  .theme--dark .mdi-magnify,
  .theme--dark .mdi-menu {
    color: white !important;
  }

  .v-menu__content {
    max-height: 80vh !important;
  }

  .v-app-bar .v-input__slot {
    box-shadow: none !important;
  }

  .v-tabs .v-tabs-bar .tab-element {
    text-transform: inherit !important;
    text-decoration: none !important;
    color: black !important;
    border-bottom: 1px solid black;
  }

  .theme--dark .v-tabs .v-tabs-bar .tab-element {
    color: white !important;
    border-bottom: 1px solid white;
  }

  .v-tabs .v-tabs-bar .tab-element.v-tab--active {
    font-weight: bold;
  }

  .v-tabs .v-tabs-slider-wrapper {
    height: 3px !important;
    color: #1B2847;
    width: 50% !important;
    display: none;
  }

  .theme--dark .v-tabs .v-tabs-slider-wrapper {
    color: white;
  }

  button.tuto-btn {
    border: none ;
    padding: 0 !important;
    transition: 0.2s;
    background-color: #ffffff;
  }

  .search .v-input__append-inner .v-input__icon .v-icon {
    color: #989898 !important;
  }

  .search .close-search {
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }

  .search input {
    transition: 0.3s;
    background-color: #F4F4F4 !important;
    border-radius: 4px;
    padding: 12px 25px 12px 12px;
    font-size: 14px;
  }

  .search input::placeholder {
    color: #1B2847 !important;
    font-family: 'Merriweather', serif;
    font-style: italic;
  }

  .search input.focused,
  .search input:focus {
    box-shadow: 0 0 3px 1px #004EDE;
    outline: none;
  }

  @media (max-width: 370px) {
    .v-toolbar__content {
      padding: 14px 10px;
    }

    .v-toolbar__content>.v-btn.v-btn--icon:first-child+.v-toolbar__title {
      padding-right: 7px;
      padding-left: 7px;
    }
  }

</style>

<style scoped>

  .logo {
    height: 45px;
  }

  a {
    display: inline-block;
    vertical-align: middle;
    color: #1B2847 !important;
  }

  .theme--dark a {
    color: white !important;
  }

  .bible {
    font-weight: bold;
    color: #b40000;
  }

  .theme--dark .bible  {
    color: #d32325;
  }

  .v-toolbar__title {
    overflow: initial;
  }

  .v-toolbar__content>.v-btn.v-btn--icon:first-child{
    margin-left: 0;
  }

  .v-app-bar {
    height: 85px !important;
    z-index: 20;
  }

  .main-navigation-router,
  .main-navigation-router span {
    font-family: 'Merriweather', serif;
    font-size: 17px;
    line-height: 22px;
    text-decoration: none !important;
  }

  .main-navigation-router span {
    font-size: 19px;
  }

  @media (max-width: 750px) {
    .main-navigation-router {
      display: none;
    }
  }

  .v-menu__content {
    -webkit-box-shadow: 0 6px 12px 0 rgba(0,0,0,0.16);
    -moz-box-shadow: 0 6px 12px 0 rgba(0,0,0,0.16);
    box-shadow: 0 6px 12px 0 rgba(0,0,0,0.16);
  }

  @media (max-width: 370px) {
    .logo {
      height: 40px;
    }

    .main-navigation-router,
    .main-navigation-router span {
      font-family: 'Merriweather', serif;
      font-size: 16px;
      line-height: 20px;
    }

    .main-navigation-router span {
      font-size: 18px;
    }
  }

  .locale-switcher {
    position: relative;
  }

  .locale-switcher .chevron {
    position: absolute;
    top: 50%;
    right: 9px;
    transform: translateY(-50%);
    z-index: -1;
  }

  .locale-switcher select {
    width: 71px;
    height: 48px;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    font-family: 'Merriweather', serif;
    border: 1px solid #1B2847;
    border-radius: 4px;
    padding: 14px 15px;
    cursor: pointer;
  }

  .close-modal {
    position: absolute;
    right: 16px;
    top: 16px;
    cursor: pointer;
  }

</style>
