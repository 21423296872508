<template>
  <footer v-if="($route.name != 'bible') || (isReRenderFooter && $route.name == 'bible')">
    <div class="container">
      <img src="/images/logo-footer-best.png" alt="">
      <p class="">© École Biblique 2024
        – <a class="external" href="https://www.bibletraditions.org/conditions-generales-dutilisation/">CGU</a>
        – <a class="external" href="https://www.bibletraditions.org/mentions-legales/">Mentions légales</a>
        – <a class="external" href="https://www.bibletraditions.org/politique-de-confidentialite/">Politique de confidentialité</a>
        – <a class="external" href="https://www.bibletraditions.org/politique-cookies/">Politique Cookies</a>
        – <a class="external" href="https://www.bibletraditions.org/contact">Contact</a>
      </p>
    </div>
  </footer>
</template>

<script>
import CookieManager from "../classes/CookieManager";

export default {
  data() {
    return {
      isBibleRoot: false,
    }
  },
  props: {
    isReRenderFooter: {
      type: Boolean,
      default: () => false,
    },
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    }
  }
};
</script>

<style scoped>
  footer {
    background-color: #101e38;
    box-shadow: 0 0 20px rgba(0,0,0,.2);
    color: #fff;
    font-size: 13px;
  }

  .container {
    padding: 29px 94px 19px 24px;
    max-width: 1158px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap : 24px;
  }

  @media (max-width: 800px) {
    .container {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  p {
    margin: 0;
  }

  @media (min-width: 800px) {
    p {
      text-align: right;
      text-wrap: balance;
    }
  }

  footer p a {
    color: white;
  }

  @media (min-width: 1500px) {
    body.panel-open footer {
      padding-right: 528px;
    }
  }

  @media (min-width: 1200px) and (max-width: 1500px) {
    body.panel-open footer {
      padding-right: 450px;
    }
  }

</style>
