<template>
  <div>
    <div class="input-wrapper tuto-highlight-research">
      <input v-model="queryTerm" @click="openResearchTable" type="text" :placeholder="$t('search placeholder')" @keyup="loadEntries" ref="recherche-input">
      <div class="close-search" @click="emptyResearch" v-if="queryTerm.length > 0"><svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-x" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="#004EDE" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M18 6l-12 12" /><path d="M6 6l12 12" /></svg></div>
    </div>
    <div v-if="token && openModal" class="customModalRecherche" ref="recherche-panel">
      <div v-if="queryTerm">
        <div v-if="isLoading" class="bloc-reload">
          <v-progress-circular indeterminate></v-progress-circular>
        </div>
        <div v-else>
          <v-tabs class="elevation-2 text-search-results" align-tabs="center">
            <v-tab v-for="tab in items" :href="`#${tab.label}`" class="tab-element">
              {{ `${tab.items.length} ${$t('search_note_in')} ${$t(tab.label)}` }}
            </v-tab>
            <v-tab-item v-for="tab in items" :value="tab.label">
              <v-card flat tile>
                <v-card-text>
                  <v-list-item v-for="item in tab.items">
                    <div v-if="tab.label === 'verses'" class="verse">
                      <div class="xref"  @click="openVerse(item)">
                        <div class="scope">{{ item.scope.abbr }}</div>
                        <div class="result" v-html="getHighlightedElementText(item.incipit)"></div>
                      </div>
                    </div>
                    <div v-else-if="tab.label==='notes'" class="note_xref" @click="openNote(item)">
                      <div class="result">
                        <p>
                          <span class="note-type">{{item.scope.abbr}} ({{ getFullNoteType(item.type) }})</span>
                          <strong v-html="getHighlightedElementText(item.title)"></strong>
                        </p>
                        <div class="cut-result" v-html="getHighlightedElementText(item.incipit)"></div>
                      </div>
                    </div>
                    <div v-else-if="tab.label==='synthNotes'" class="synth_link" @click="openSynthNote(item)">
                      <div class="result" v-html="item.title">
                      </div>
                    </div>
                    <div v-else-if="tab.label==='margRefs'">
                      <div class="margref-item">
                        <div class="scope">{{ item.scope.abbr }}</div>
                        <div class="result" v-html="item.incipit"></div>
                      </div>
                    </div>
                  </v-list-item>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs>
        </div>
      </div>
      <div v-else>
        <v-tabs class="elevation-2 testament-search">
          <v-tabs-slider></v-tabs-slider>
          <v-tab href="#tab-AT" class="tab-element">
            {{ $t('old testament') }}
          </v-tab>
          <v-tab href="#tab-NT" class="tab-element">
            {{ $t('new testament') }}
          </v-tab>
          <v-tab-item value="tab-AT">
            <v-card flat tile>
              <v-card-text>
                <v-list-item v-for="(group, index) in AT" :key="index" @click="closeResearchTable">
                  <router-link
                      :to="bookByAbbreviation(abbreviation) ? '/bible/' + bookByAbbreviation(abbreviation).url_param + '/1' : '#'"
                      v-for="abbreviation in group.books" :key="abbreviation"
                      :class="'book ' + (bookByAbbreviation(abbreviation) ? 'book-' + bookByAbbreviation(abbreviation).url_param : '')"
                      :title="bookByAbbreviation(abbreviation) ? bookByAbbreviation(abbreviation).name : ''"
                  >
                    {{ abbreviation }}
                  </router-link>
                </v-list-item>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item value="tab-NT">
            <v-card flat tile>
              <v-card-text>
                <v-list-item v-for="(group, index) in NT" :key="index" @click="closeResearchTable">
                  <router-link
                      :to="bookByAbbreviation(abbreviation) ? '/bible/' + bookByAbbreviation(abbreviation).url_param + '/1' : '#'"
                      v-for="abbreviation in group.books" :key="abbreviation"
                      :class="'book ' + (bookByAbbreviation(abbreviation) ? 'book-' + bookByAbbreviation(abbreviation).url_param : '')"
                      :title="bookByAbbreviation(abbreviation) ? bookByAbbreviation(abbreviation).name : ''"
                  >
                    {{ abbreviation }}
                  </router-link>
                </v-list-item>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </div>
    </div>
    <div v-else-if="!token" class="no-access-popin">
      <div class="popin">
        <p v-html="$t('inaccessible_search')"></p>
      </div>
    </div>
  </div>
</template>

<script>
import BestApiService from '../services/BestApiService.js';
import {debounce} from "debounce";
import Login from "./Login.vue";
import Registration from "./Registration.vue";
import VerseModalContentClass from "../classes/VerseModalContentClass";
import NoteModalContentClass from "../classes/NoteModalContentClass";
import SynthModalContentClass from "../classes/SynthModalContentClass";
import NoteTypeMatcher from "../classes/Utils/NoteTypeMatcher";

export default {
  components: { Login, Registration },
  computed: {
    AT: function () {
      return this.toc ? this.toc.parts[0].groupings : {};
    },
    NT: function () {
      return this.toc ? this.toc.parts[1].groupings : {};
    },
    search: {
      get() {
        return this.queryTerm;
      },
      set(searchInput) {
        if (this.queryTerm !== searchInput) {
          this.queryTerm = searchInput;
          if (this.wait) {
            clearTimeout(this.wait);
          }
          this.wait = setTimeout(this.loadEntries, 500);
        }
      },
    },
  },
  data() {
    return {
      registers: {},
      isLoading: false,
      items: [],
      model: null,
      queryTerm: '',
      toc: null,
      wait: null,
      currentTab: 'verses',
      openModal: false,
    }
  },
  mounted() {
    this.fetchContent();
    window.$vueEventBus.$on('locale_change', this.fetchContent);
    window.$vueEventBus.$on('user_change', () => {
      this.fetchContent(true);
      this.$forceUpdate();
    });

    window.$vueEventBus.$on('closeResearch', () => {
      this.emptyResearch(true)
      this.openModal = false;
    })
  },
  methods: {
    openVerse(item) {
      item.text = item.incipit;
      let modalContent = new VerseModalContentClass(item.scope.url_param, this.$store, item)
      window.$vueEventBus.$emit('openModal', modalContent);
    },
    openNote(item) {
      item.text = item.combined
      let modalContent = new NoteModalContentClass(item.type+item.scope.url_param, this.$store, item.type, item)
      window.$vueEventBus.$emit('openModal', modalContent);
    },
    openSynthNote(item) {
      item.text = item.incipit;
      let modalContent = new SynthModalContentClass(item.id, this.$store, item)
      window.$vueEventBus.$emit('openModal', modalContent);
    },
    bookByAbbreviation(abbr) {
      return this.toc ? this.$store.getters.getBookByAbbreviation(abbr) : null;
    },
    getFullNoteType(type) {
      return (new NoteTypeMatcher).getNoteType(this.registers, type)
    },
    openResearchTable() {
      this.openModal = true;
      this.$refs['recherche-input'].style.display = 'block';
    },
    closeResearchTable() {
      this.openModal = false;
    },
    getLinkNote(item) {
      return '#' + item.type + item.scope.url_param
    },
    loadEntries:
        debounce(function (e) {
          this.isLoading = true;

          this.items = [
            {label: 'verses', items: []},
            {label: 'margRefs', items: []},
            {label: 'notes', items: []},
            {label: 'synthNotes', items: []},
          ]
          if (!this.queryTerm || this.queryTerm.length < 2) {
            this.isLoading = false;
            return;
          }

          BestApiService.search(this.$store, this.queryTerm)
              .then(res => {
                const {verses, 'marginal-references' : margRefs, notes, 'synthetic-notes': synthNotes} = res;

                this.items = [
                  {label: 'verses', items: verses || []},
                  {label: 'margRefs', items: margRefs || []},
                  {label: 'notes', items: notes || []},
                  {label: 'synthNotes', items: synthNotes || []},
                ]

                this.isLoading = false;
              }).catch(test => {});
        }, 500),
    getHighlightedElementText(text) {
      const element = $(`<div>${text}</div>`);
      element.find('p').each(function (i, elem) {
        $(elem).replaceWith('<span>' + $(elem).html() + '</span> ');
      });
      element.find('.verse_number, .text_trad, .variant_n, iframe, figure, h4, h5, h6').remove();
      element.find('span').replaceWith(function () {
        return $(this).contents();
      }).end();
      for (const word of this.queryTerm.split(' ')) {
        element.find('*').each(function () {
          if (!$(this).children().length) {
            $(this).html($(this).text().replace(new RegExp(word, 'giu'), '<span class="font-weight-black">$&</span>'));
          }
        });
      }
      return element.html();
    },
    fetchContent(wantReload = false) {
      BestApiService.getRubrics(this.$store, wantReload,(registers) => {
        this.registers = registers['AT']
      });
      BestApiService.getToc(this.$store, wantReload, (json) => {
        this.toc = json;
        this.$forceUpdate();
      });
      this.$forceUpdate();
    },
    emptyResearch(closeTable = false) {
      this.queryTerm = '';
      this.openModal = false;
      if(!closeTable) {
        this.$refs['recherche-input'].focus()
      }
    }
  },
  name: 'Search',
  props: {
    user: {
      type: Object,
      default: () => {
        return {}
      }
    },
    token: {
      type: String,
      default: () => {
        return ''
      }
    },
  },
}
</script>

<style scoped>

.bloc-reload {
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
}

.customModalRecherche {
  position: absolute;
  top: 85px;
  left: 0;
  width: 100%;
  height: calc(100vh - 85px);
  display: flex;
  flex-direction: column;
  overflow-Y: auto;
  background-color: rgba(0,0,0, 0.4);
}

.customModalRecherche .text-search-results > .v-item-group {
  position: sticky;
  top: 0;
  z-index: 10;
}

.scope {
  font-weight: bold;
  color: #C91B23 !important;
}

.note-type {
  color: #C91B23 !important;
}

.result {
  overflow: hidden;
}

.result .cut-result {
  word-break: break-word;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.result, .scope {
  color: #000;
}

.book {
  width: 45px;
  height: 45px;
  background: purple;
  color: #fff;
  margin: 3px;
  text-align: center;
  vertical-align: middle;
  line-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-family: 'Merriweather', serif;
}

.tab-element {
  width: 50%;
  color: black;
  text-align: center;
  font-size: 16px;
  line-height: 18px;
  padding: 10px 0;
  position: relative;
  font-family: 'Merriweather', serif;
  letter-spacing: unset;
}

.v-tabs-bar__content .tab-element.v-tab--active {
  background-color: #e5e2e2;
}

.v-tabs-bar__content .tab-element .v-ripple__container {
  display: none !important;
  opacity: 0 !important;
}

.book-Gn {
  background-color: #50998C;
}

.book-Ex {
  background-color: #50998C;
}

.book-Lv {
  background-color: #50998C;
}

.book-Nb {
  background-color: #50998C;
}

.book-Dt {
  background-color: #50998C;
}

.book-Jos {
  background-color: #427C89;
}

.book-Jg {
  background-color: #427C89;
}

.book-Rt {
  background-color: #427C89;
}

.book-1S {
  background-color: #427C89;
}

.book-2S {
  background-color: #427C89;
}

.book-1K {
  background-color: #427C89;
}

.book-2K {
  background-color: #427C89;
}

.book-1Ch {
  background-color: #427C89;
}

.book-2Ch {
  background-color: #427C89;
}

.book-Ezr {
  background-color: #427C89;
}

.book-Ne {
  background-color: #427C89;
}

.book-Tb {
  background-color: #DDBB7F;
}

.book-Jdt {
  background-color: #DDBB7F;
}

.book-Est {
  background-color: #427C89;
}

.book-1M {
  background-color: #DDBB7F;
}

.book-2M {
  background-color: #DDBB7F;
}

.book-Jb {
  background-color: #817BAA;
}

.book-Ps {
  background-color: #817BAA;
}

.book-Pr {
  background-color: #817BAA;
}

.book-Qo {
  background-color: #817BAA;
}

.book-Sg {
  background-color: #817BAA;
}

.book-Ws {
  background-color: #DDBB7F;
}

.book-Si {
  background-color: #DDBB7F;
}

.book-Is {
  background-color: #CE5B90;
}

.book-Jr {
  background-color: #CE5B90;
}

.book-Lm {
  background-color: #817BAA;
}

.book-Ba {
  background-color: #DDBB7F;
}

.book-Ezk {
  background-color: #CE5B90;
}

.book-Dn {
  background-color: #CE5B90;
}

.book-Ho {
  background-color: #A5A5A5;
}

.book-Jl {
  background-color: #A5A5A5;
}

.book-Am {
  background-color: #A5A5A5;
}

.book-Ob {
  background-color: #A5A5A5;
}

.book-Jon {
  background-color: #A5A5A5;
}

.book-Mi {
  background-color: #A5A5A5;
}

.book-Na {
  background-color: #A5A5A5;
}

.book-Hab {
  background-color: #A5A5A5;
}

.book-Zp {
  background-color: #A5A5A5;
}

.book-Hg {
  background-color: #A5A5A5;
}

.book-Zc {
  background-color: #A5A5A5;
}

.book-Ml {
  background-color: #A5A5A5;
}

.book-Mt {
  background-color: #50998C;
}

.book-Mk {
  background-color: #50998C;
}

.book-Lk {
  background-color: #50998C;
}

.book-Jn {
  background-color: #50998C;
}

.book-Ac {
  background-color: #427C89;
}

.book-Rm {
  background-color: #817BAA;
}

.book-1Co {
  background-color: #817BAA;
}

.book-2Co {
  background-color: #817BAA;
}

.book-Ga {
  background-color: #817BAA;
}

.book-Ep {
  background-color: #817BAA;
}

.book-Ph {
  background-color: #817BAA;
}

.book-Col {
  background-color: #817BAA;
}

.book-1Th {
  background-color: #817BAA;
}

.book-2Th {
  background-color: #817BAA;
}

.book-1Tm {
  background-color: #817BAA;
}

.book-2Tm {
  background-color: #817BAA;
}

.book-Tt {
  background-color: #817BAA;
}

.book-Phm {
  background-color: #817BAA;
}

.book-Heb {
  background-color: #CE5B90;
}

.book-Jm {
  background-color: #CE5B90;
}

.book-1P {
  background-color: #CE5B90;
}

.book-2P {
  background-color: #CE5B90;
}

.book-1Jn {
  background-color: #CE5B90;
}

.book-2Jn {
  background-color: #CE5B90;
}

.book-3Jn {
  background-color: #CE5B90;
}

.book-Jd {
  background-color: #CE5B90;
}

.book-Rv {
  background-color: #A5A5A5;
}

.v-application .v-list .elevation-2 {
  box-shadow: none !important;
}

.hidden {
  display: none;
}

.input-wrapper {
  position: relative;
}

</style>