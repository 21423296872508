<template>
<div>
  <div class="close-modal close-login-modal"><svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-x" width="28" height="28" viewBox="0 0 24 24" stroke-width="1.5" stroke="#000000" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M18 6l-12 12" /><path d="M6 6l12 12" /></svg></div>
  <div class="registration-welcome">
    <h2>{{ $t('update_account') }}</h2>
    <p class="text-red" v-if="error">{{ error }}</p>
    <p class="text-green" v-if="success">{{ $t('profil_updated_successfully') }}</p>
    <div><form @submit="submitForm">
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label for="lastname">{{ $t('lastname') }}</label>
            <input name="lastname" id="lastname" type="text" v-model="lastname" class="form-control" :placeholder="$t('lastname_placeholder')" required/>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="firstname">{{ $t('firstname') }}</label>
            <input name="firstname" id="firstname" type="text" v-model="firstname" class="form-control" :placeholder="$t('firstname_placeholder')" required/>
          </div>
        </div>
      </div>

      <div class="form-group">
        <label for="email">{{ $t('email') }}</label>
        <input name="email" type="email" id="email" v-model="email" class="form-control" :placeholder="$t('email_placeholder')" required/>
      </div>

      <div class="row">
        <div class="col-md-6">

          <div class="form-group">
            <label for="password">{{ $t('password_create') }}</label>
            <input name="password" id="password_first" type="password" v-model="newPassword" class="form-control" :placeholder="$t('password_placeholder')"/>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="repeated_password">{{ $t('repeated_password') }}</label>
            <input name="password_repeated" id="password_second" type="password" v-model="newRepeatedPassword" class="form-control"/>
          </div>
        </div>
      </div>

      <div class="buttons">
        <button @click="hideUpdateAccount" class="btn-red">{{ $t('back') }}</button>
        <button class="btn-red full" type="submit">{{ $t('update_account_button') }}</button>
      </div>
    </form>
    </div>
  </div>
</div>
</template>

<script>
import BestApiService from '../services/BestApiService.js';
import Routing from '../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js';

export default {
  data () {
    return {
      error: '',
      firstname: null,
      lastname: null,
      email: null,
      newPassword: null,
      newRepeatedPassword: null,
      success: false,
      Routing,
    }
  },
  mounted() {
      if(this.user) {
        this.firstname = this.user.firstname
        this.lastname = this.user.lastname
        this.email = this.user.email
      }
      this.success = false;
  },
  methods: {
    submitForm (e) {
      e.preventDefault();
      let formData = new FormData(e.target);
      let updatedUser = {};
      let password = {};
      formData.forEach(function (value, key) {
        if (['password', 'password_repeated'].includes(key)) {
          password[key] = value;
        } else {
          updatedUser[key] = value;
        }
      });
      updatedUser.password = password;
      this.error = '';
      this.success = false;
      BestApiService.updateAccount(updatedUser)
          .then(json => {
            if (json.success) {
              let user = json.user;
              this.$store.commit('SET_USER', user);
              this.$store.commit('SET_TOKEN', json.token);
              this.$store.commit('SET_API_TOKEN', json.token_content);
              this.firstname = user.firstname
              this.lastname = user.lastname
              this.email = user.email
              this.success = true;
            } else {
              Object.values(json.errors).forEach(error => {
                this.error += error.join(', ');
              })
            }
          })
          .catch((error) => {
            this.error = this.$t('error');
          })
    },
    hideUpdateAccount(){
      window.$vueEventBus.$emit('hideUpdateAccount');
    }
  },
  props: {
    user: {
      type: Object,
      default: () => {
        return {}
      }
    },
  },
}
</script>

<style scoped>

  h1 {
    font-weight: 700;
    font-family: 'Merriweather', serif;
    margin-bottom: 30px;
    font-size: 24px;
    font-weight: 700;
    color: #C91B23;
  }

  label {
    color: black;
    font-weight: bold;
  }

  .btn-primary {
    min-width: 160px;
    font-weight: bold;
  }

  .close-modal {
    position: absolute;
    right: 16px;
    top: 16px;
    cursor: pointer;
  }
</style>