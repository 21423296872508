<template>
  <div class="tradition-configuration-wrapper traditions">
    <div v-if="!token" class="no-access-traditions">
      <p v-html="$t('inaccessible_search')"></p>
    </div>

    <div class="tradition-configuration-content" :class="token ? '' : 'no-access'">
      <p v-if="error" class="error">{{ error }}</p>
      <div>
        <p class="mb-0"><strong>{{ $t('active_traditions') }}</strong></p>
        <div class="switcher" v-for="traditionAbbreviation in availableTraditions"
             :title="getTraditionObject(traditionAbbreviation).isDefault ? $t('impossible_remove_tradition') : false">
          <input type="checkbox" :id="`tradition_${traditionAbbreviation}`" :value="traditionAbbreviation"
                 :disabled="getTraditionObject(traditionAbbreviation).isDefault" v-model="checkedTraditions"
                 @change="updateActiveTradition()">
          <label :for="`tradition_${traditionAbbreviation}`">
            <div>
              <span class="tradition-abbreviation"
                    :style="`--color-badge:${getTraditionObject(traditionAbbreviation).color}`">{{
                  traditionAbbreviation
                }}</span>
              <span>{{ getTraditionObject(traditionAbbreviation).labelPanelConfiguration[locale] }}</span>
            </div>
            <span class="slider" :class="getTraditionObject(traditionAbbreviation).isDefault ? 'disabled' : ''"></span>
          </label>
        </div>
      </div>
      <div>
        <p class="mb-2 mb-md-7"><strong>{{ $t('tradition_display_rule') }}</strong></p>
        <div class="custom-radio">
          <input type="radio" id="traduction_configuration_open" name="traduction_configuration" value="open"
                 v-model="checkedTraductionConfiguration" @change="updateTraductionConfiguration()">
          <label for="traduction_configuration_open">
            <span class="radio-toggle"></span>
            {{ $t('tradition_display_show') }}
          </label>
          <input type="radio" id="traduction_configuration_close" name="traduction_configuration" value="close"
                 v-model="checkedTraductionConfiguration" @change="updateTraductionConfiguration()">
          <label for="traduction_configuration_close">
            <span class="radio-toggle"></span>
            {{ $t('tradition_display_hide') }}
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BestApiService from '../services/BestApiService.js'
import router from '../router';
import Login from "./Login.vue";
import Registration from "./Registration.vue";

export default {
  components: { Login, Registration },
  //beforeDestroy() {
//    window.$vueEventBus.$off();
//  },
  computed: {
    mainTradition: function () {
      return this.$store.getters.getMainTradition();
    },
    activesTraditions: function () {
      return this.$store.getters.getActivesTraditions();
    },
    traditions: function () {
      return this.$store.getters.getAllTraditions();
    },
    isLoggedIn : function () {
      return !!this.$store.state.user
    }
  },
  data() {
    return {
      checkedTraditions: [],
      checkedTraductionConfiguration: 'close',
      error: null,
      token: '',
    }
  },
  mounted() {
    this.fetchContent();
    window.$vueEventBus.$on('user_change', () => {
      this.fetchContent();
      this.$forceUpdate();
    });

    this.token = this.$store.getters.getToken();
    this.fetchContent();
    window.$vueEventBus.$on('user_change', () => {
      this.token = this.$store.getters.getToken();
      this.user = this.$store.getters.getUser();
      this.fetchContent();
      this.$forceUpdate();
    });
  },
  methods: {
    fetchContent() {
      this.checkedTraditions = this.activesTraditions;
      this.checkedTraductionConfiguration = this.$store.getters.getTraductionConfiguration()

      if (this.token && !this.user) {
        BestApiService.fetchUser()
            .then(json => {
              if (json.status == 'success') {
                this.user = json.user;
                this.$store.commit('SET_USER', this.user);
                this.$store.commit('SET_API_TOKEN', json.token_content);
              }
            });
      }
      this.$forceUpdate();
    },
    updateActiveTradition() {
      if (this.isLoggedIn) {
        this.updateUserConfig()
      }
      this.$store.commit('SET_ACTIVE_TRADITIONS', this.checkedTraditions);
    },
    updateTraductionConfiguration() {
      if (this.isLoggedIn) {
        this.updateUserConfig()
      }
      this.$store.commit('SET_TRADUCTION_CONFIGURATION', this.checkedTraductionConfiguration);
    },
    updateUserConfig() {
      BestApiService.editConfigUser({traductions_display_mode : this.checkedTraductionConfiguration, active_traditions: this.checkedTraditions})
          .then(json => {
            if(json.success) {
              if(json.user) {
                this.$store.commit('SET_USER', json.user);
                this.$store.commit('SET_API_TOKEN', json.token_content);
              }
            } else {
              setTimeout(() => {
                this.error = null;
              }, "2500")
              this.fetchContent()
            }
          })
          .catch((error) => {
            this.error = this.$t('error_edit_config');
            setTimeout(() => {
              this.error = null;
            }, "2500")
            this.fetchContent()
          })
    },
    getTraditionObject(traditionAbbreviation) {
      return this.traditions[traditionAbbreviation] ?? this.traditions.default;
    },
  },
  props: {
    availableTraditions: {
      type: Array,
      default: () => {
        return []
      }
    },
    locale: {
      type: String,
      default: () => {
        return 'fr'
      }
    },
  },
}
</script>

<style>
.tradition-configuration-wrapper {
  position: absolute;
  left: 0;
  top: calc(100% + 1px);
  right: 0;
  background-color: #F4F4F4;
  z-index: 10;
  border-right: 1px solid #e8e8e8;
}

.tradition-configuration-wrapper .tradition-configuration-content {
  max-width: 680px;
  margin: 0 auto;
  display: flex;
  grid-gap: 80px;
  justify-content: space-between;
  padding: 16px 24px;
}

@media (max-width: 700px) {
  .tradition-configuration-wrapper .tradition-configuration-content {
    flex-direction: column;
    grid-gap: 30px;
  }
}

@media (min-width: 700px) {
  .tradition-configuration-wrapper .tradition-configuration-content > div {
    width: 50%;
  }
}

.no-access-traditions {
  max-width: 680px;
  margin: 0 auto;
  padding: 32px 24px 16px 24px;
}

.no-access-traditions p {
  padding: 12px;
  margin-bottom: 0 !important;
  border: 1px solid #B40000;
  border-radius: 6px;
  background: #b400000d;
}

.no-access-traditions p a {
  color: #B40000;
}

.tradition-configuration-wrapper .tradition-configuration-content.no-access {
  opacity: 0.5;
  cursor: not-allowed;
}

.tradition-configuration-wrapper .tradition-configuration-content.no-access * {
  pointer-events: none;
}

.chapter-title {
  text-align: center;
  font-size: 16px;
  line-height: 20px;
  color: #1B2847;
  font-weight: 700;
  margin-top: 31px;
  margin-bottom: 24px;
}

.tradition-configuration-wrapper .chapters {
  max-width: 672px;
  margin: 0 auto;
  padding-bottom: 40px;
}

.tradition-abbreviation {
  color: var(--color-badge, black);
}
</style>
