<template>
  <div>
    <div class="bible-content-wrapper">
      <div class="content-left" :class="isPanelNoteOpen ? 'panel-open' : ''">
        <BibleNavigation ref="navigation"
                         :availableTraditions="availableTraditions"
                         :currentChapter="chapter"
                         :mainTradition="mainTradition"
        />
        <div class="container content-bible">
          <div v-if="!isLoading">
            <Verse v-for="(currentLoopVerse, index) in uniqueVerses"
                   :class="(verse && verse-1 == index) || !verse && index === 0 ? ' tuto-highlight-first-verse' : ''"
                   :currentChapter="chapter"
                   :key="currentLoopVerse.id"
                   :id="currentLoopVerse.scope.relative"
                   :verse="currentLoopVerse"
                   :isActiveNotesVerse="noteVerseId === currentLoopVerse.id"
                   :currentNote="noteVerseId === currentLoopVerse.id ? currentNote : null"
                   :mainTradition="mainTradition"
                   :lastVerseFromChapter="index + 1 === uniqueVerses.length"
                   :firstVerse="index === 0"
                   :locale="locale"
                   @click.native="openNotes(currentLoopVerse.id)"
                   :ref="'verse-' + currentLoopVerse.scope.relative"
                   :data-verse-id="currentLoopVerse.id"
                   :highlighted="highlightedVerseIds.includes('verse-' + currentLoopVerse.scope.relative)"
            />
            <div class="pagination">
              <div class="arrow-left">
                <router-link :to="{name: 'bible', params: {bookAbbreviation:prevBook.url_param, chapter: prevChapter}, query: { verse: verse }}" v-if="prevBook && prevChapter" ref="prev" class="btn-box">
                  <svg xmlns="http://www.w3.org/2000/svg" class="chevron-left" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="#1B2847" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M15 6l-6 6l6 6" /></svg>
                  {{ prevText }}
                </router-link>
              </div>
              <div class="arrow-right">
                <router-link :to="{name: 'bible', params: {bookAbbreviation:nextBook.url_param, chapter: nextChapter}, query: { verse: verse }}" v-if="nextBook && nextChapter" ref="next" class="btn-box">
                  {{ nextText }}
                  <svg style="rotate: 180deg" xmlns="http://www.w3.org/2000/svg" class="chevron-right" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="#1B2847" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M15 6l-6 6l6 6" /></svg>
                </router-link>
              </div>
            </div>
            <Footer :is-re-render-footer="true"/>
          </div>
          <p v-else-if="error" class="text-danger">{{ error }}</p>
          <div v-else class="text-center loader-wrapper">
            <div class="loader"></div>
          </div>
        </div>
      </div>
      <Notes :notes="notesForVerse" :margsRefs="margsRefByVerse" :testament="testament" :activeVerse="noteVerseId"/>
    </div>
  </div>
</template>

<script>
import BestApiService from '../services/BestApiService.js';
import BibleNavigation from '../components/BibleNavigation';
import Notes from '../components/Notes';
import Verse from '../components/Verse';
import ModalContentClass from "../classes/ModalContentClass";
import Shepherd from "shepherd.js";
import CookieManager from "../classes/CookieManager";
import Footer from "../components/Footer.vue";

export default {

  //beforeDestroy() {
//    window.$vueEventBus.$off();
//  },
  components: {Footer, BibleNavigation, Notes, Verse},
  computed: {
    mainTradition: function () {
      return 'V';
    },
    activeBook: function () {
      return this.toc ? this.toc.books.find(book => book.url_param === this.bookAbbreviation) : null;
    },
    availableTraditions: function () {
      if (!this.uniqueVerses.length) {
        return []
      }
      let traditions = this.uniqueVerses[0]['textual-traditions']

      return traditions.flat()
    },
    activesTraditions: function () {
      return this.$store.getters.getActivesTraditions();
    },
    uniqueVerses: function () {
      const result = [];
      const map = new Map();
      var verses = this.book_verses.filter(verse => {
        // For books with only one chapter, verse.scope.relative contains the verse number, else "chap,num".
        const chapter = verse.scope.url_param.includes('.') ? verse.scope.url_param.split('.')[0] : this.chapter;
        return chapter === this.bookAbbreviation + this.chapter;
      });
      for (const item of verses) {
        if (!map.has(item.id)) {
          map.set(item.id, true);
          result.push(item);
        }
      }
      return result;
    },
    needNextBook: function() {
      var next = parseInt(this.chapter) + 1;
      return this.activeBook && next > this.activeBook.num_chapters
    },
    nextBook: function() {
      var index = this.toc ? this.toc.books.indexOf(this.activeBook) : -1;
      let nextBook = index != -1 && index < this.toc.books.length - 1 && this.toc.books[index + 1] ? this.toc.books[index + 1] : null;
      return this.needNextBook ? nextBook : this.activeBook;
    },
    nextChapter: function () {
      var next = parseInt(this.chapter) + 1;
      if (!this.needNextBook) {
        return next;
      }
      return this.nextBook ? 1 : null;
    },
    nextText: function () {
      var next = parseInt(this.chapter) + 1;
      if (this.activeBook && next <= this.activeBook.num_chapters) {
        return (this.activeBook.url_param == 'Ps' ? this.$t('psalm') : this.$t('chapter')) + ' ' + next;
      }
      return this.nextBook ? this.$t('next book') + ' (' + this.nextBook.name + ')' : '';
    },
    needPrevBook: function() {
      var prev = parseInt(this.chapter) - 1;
      return prev <= 0;
    },
    prevBook: function() {
      var index = this.toc ? this.toc.books.indexOf(this.activeBook) : -1;
      let book = index > 0 && this.toc.books[index - 1] ? this.toc.books[index - 1] : null;
      return this.needPrevBook ? book : this.activeBook;
    },
    prevChapter: function () {
      var prev = parseInt(this.chapter) - 1;
      if (!this.needPrevBook && this.activeBook) {
        return Math.min(prev, this.activeBook.num_chapters);
      }
      return this.prevBook ? this.prevBook.num_chapters : null;
    },
    prevText: function () {
      var prev = parseInt(this.chapter) - 1;
      if (prev > 0 && this.activeBook) {
        return (this.activeBook.url_param == 'Ps' ? this.$t('psalm') : this.$t('chapter')) + ' ' +  Math.min(prev, this.activeBook.num_chapters);
      }
      return this.prevBook ? this.$t('previous book') + ' (' + this.prevBook.name + ')' : '';
    },
  },
  created() {
    this.bookAbbreviation = this.$route.params.bookAbbreviation || this.bookAbbreviation;
    this.chapter = this.$route.params.chapter || this.chapter;
    this.traditions = this.$store.getters.getAllTraditions();
    this.verse = this.$route.query.verse || this.verse;
  },
  data() {
    return {
      bookAbbreviation: null,
      toc: null,
      book_verses: [],
      chapter: 1,
      error: '',
      isLoading: true,
      testament: 'AT',
      verse: null,
      traditions: [],
      noteVerseId: null,
      verseId: this.$route.query.verseId || null,
      currentNote: null,
      locale: null,
      notesForVerse: [],
      margsRefByVerse: [],
      cookieManager: new CookieManager(),
      isPanelNoteOpen: false,
      highlightedVerseIds: [],
    }
  },
  methods: {
    async scrollIntoViewPromise(node, options) {
      node.scrollIntoView(options);

      return new Promise((resolve) => {
        const intersectionObserver = new IntersectionObserver((entries) => {
          const [entry] = entries;
          if (entry.isIntersecting) {
            setTimeout(() => {
              resolve(true);
              intersectionObserver.unobserve(node);
            }, 100);
          }
        });
        intersectionObserver.observe(node);
      });
    },
    scrollToVerse() {
      this.cookieManager.setCookie('bibleVisited', true);
      this.$nextTick(() => {
        if (this.verseId && this.verseId != 0) {
          const selector = `[data-verse-id="${this.verseId}"]`;
          const element = document.querySelector(selector);
          if (element) {
            this.scrollIntoViewPromise(element,{ behavior: 'instant' }).then(() => {
              document.dispatchEvent(new CustomEvent('scroll-done'));
            });
          }
        } else{
          document.dispatchEvent(new CustomEvent('scroll-done'));
        }
      });
    },
    fetchContent(wantReload = false) {
      this.error = '';
      this.isLoading = true;
      let content = this.$store.getters.getBook(this.bookAbbreviation);
      if (content && !wantReload) {
        this.updateText(content);
      } else {
        BestApiService.fetchBook(this.$store, this.bookAbbreviation)
            .then(responseData => {
              if (responseData === null || typeof responseData === 'undefined') {
                throw new Error('no-op');
              }
              this.$store.commit('SET_BOOK', {
                id: this.bookAbbreviation,
                edition: this.$store.getters.getBibleEdition(),
                content: responseData,
              });
              window.$vueEventBus.$emit('end_loading_panel_note');

              setTimeout(() => {
                this.initTuto();
              },500)

              return this.updateText(responseData);
            }).catch(error =>
            this.error = this.$t('unavailable')
        );
      }
      BestApiService.getToc(this.$store, wantReload, (json) => {
        this.toc = json;
        const book = this.$store.getters.getBookByUrlParam(this.bookAbbreviation);
        this.testament = book && this.toc && this.toc.parts[1].books.includes(this.bookAbbreviation) ? 'NT' : 'AT';
        this.$forceUpdate();
      });
      this.locale = this.$store.getters.getLocale();
    },
    updateText(json) {
      this.book_marg_refs = json['marginal-references'];
      this.book_notes = json.notes;
      this.book_verses = json.verses;
      this.isLoading = false;
      this.setNotesForVerse();
      this.setMargRefsForVerse();
      if(this.noteVerseId) {
        this.notesForVerse = this.$store.getters.getNotesByVerse(this.noteVerseId);
        this.margsRefByVerse = this.$store.getters.getMargRefsByVerse(this.noteVerseId);
      }
      this.$forceUpdate();
      this.scrollToVerse();
    },
    openNotes(noteVerseId) {
      this.noteVerseId = noteVerseId;
      this.notesForVerse = this.$store.getters.getNotesByVerse(this.noteVerseId);
      this.margsRefByVerse = this.$store.getters.getMargRefsByVerse(this.noteVerseId);
      if (noteVerseId) {
        window.$vueEventBus.$emit('open_panel_note', 'note');
      }
      this.nextStepTuto('verse-note-step')
    },
    setNotesForVerse: function () {
      var notes = {};
      for (const verse of this.uniqueVerses) {
        notes[verse.id] = [];
        for (const pointer of verse.notes) {
          const num = pointer.replace('#/notes/', '');
          notes[verse.id].push(this.book_notes[num]);
        }
      }
      this.$store.commit('SET_NOTES_VERSE', notes);
    },
    setMargRefsForVerse: function () {
      var margRefs = {};
      for (const verse of this.uniqueVerses) {
        margRefs[verse.id] = [];
        for (const pointer of verse['marginal-references']) {
          const num = pointer.replace('#/marginal-references/', '');
          margRefs[verse.id].push(this.book_marg_refs[num]);
        }
      }
      this.$store.commit('SET_MARG_REFS_VERSE', margRefs);
    },
    text_variant_size() {
      var intr = setInterval(function() {
        if ($('.bible-content-wrapper .content-bible .verse_part').length) {
          $('.bible-content-wrapper .content-bible .verse_part .text-with-variant .text-variant').each(function () {
            // Show invisible siblings
            $(this).parents('.verse_part').find('.base-text.elipsis-text').addClass('show-elipsis');

            // Get sibling .base-text left offset to parent .text-with-variant
            let leftOffset = $(this).siblings('.base-text').offset().left;

            // Remove parent left offset
            leftOffset -= $(this).parents('.verse_part').offset().left;


            // Prevent overflow
            if($(this).width() + leftOffset > $(this).parents('.verse_part').width()) {
              leftOffset = leftOffset - (leftOffset + $(this).width() - $(this).parents('.verse_part').width());
            }
            if(leftOffset < 0) {
              leftOffset = 0;
            }

            $(this).parents('.verse_part').find('.base-text.elipsis-text').removeClass('show-elipsis');
            $(this).css('left', leftOffset+'px')
          });

          clearInterval(intr);
        }
      }, 200)
    }, initTuto(fromEvent = false) {
      if(this.cookieManager.getCookie('bibleVisited', false)) {
        return;
      }
      this.tour = new Shepherd.Tour({
        useModalOverlay: true,
        defaultStepOptions: {
          scrollTo: {behavior: 'smooth', block: 'center'}
        },
        steps: [
          // {
          //   text: this.$t('tuto.hover_verse.content'),
          //   attachTo: {element: document.querySelector('.tuto-highlight-first-verse'), on: 'bottom'},
          //   classes: 'mt-4',
          //   canClickTarget: false,
          //   modalOverlayOpeningPadding: 10,
          //   modalOverlayOpeningRadius: 5,
          //   buttons: [
          //     {
          //       action() {
          //         return this.cancel();
          //       },
          //       text: this.$t('tuto.button.close'),
          //     },
          //     {
          //       action() {
          //         return this.next();
          //       },
          //       text: this.$t('tuto.button.next'),
          //     }
          //   ],
          //   id: 'verse-hover'
          // },
          {
            text : this.$t('tuto.hover_verse.content'),
            attachTo: {element: document.querySelector('.tuto-highlight-first-verse'), on: 'bottom'},
            scrollTo: false,
            classes: 'mt-4',
            modalOverlayOpeningPadding: 10,
            modalOverlayOpeningRadius: 5,
            buttons: [
              {
                action() {
                  return this.cancel();
                },
                text: this.$t('tuto.button.close'),
              },
            ],
            id: 'verse-note-step'
          },
          {
            text : this.$t('tuto.note_click.content'),
            attachTo: {element: document.querySelector('.note-content'), on: 'left-start'},
            scrollTo: false,
            classes: 'ml--4',
            modalOverlayOpeningPadding: 10,
            modalOverlayOpeningRadius: 5,
            buttons: [
              {
                action() {
                  return this.cancel();
                },
                text: this.$t('tuto.button.close'),
              },
              {
                action() {
                  return this.next();
                },
                text: this.$t('tuto.button.next'),
              },
            ],
            id: 'note-click-step'
          },
          {
            text: this.$t('tuto.tradition_config.content'),
            attachTo: {element: document.querySelector('.tuto-highlight-tradition-config'), on: 'bottom'},
            classes: 'mt-4',
            modalOverlayOpeningPadding: 10,
            modalOverlayOpeningRadius: 5,
            buttons: [
              {
                action() {
                  return this.cancel();
                },
                text: this.$t('tuto.button.close'),
              },
              {
                action() {
                  return this.next();
                },
                text: this.$t('tuto.button.next'),
              }
            ],
          },
          {
            title: this.$t('tuto.research.title'),
            text : this.$t('tuto.research.content'),
            // Pas de partage de refs entre composants
            attachTo: { element: document.querySelector('.tuto-highlight-research'), on: 'bottom'},
            classes: 'mt-4',
            modalOverlayOpeningPadding: 10,
            modalOverlayOpeningRadius: 5,
            buttons: [
              {
                action() {
                  return this.cancel();
                },
                text: this.$t('tuto.button.close'),
              },
              {
                action() {
                  return this.next();
                },
                text: this.$t('tuto.button.next'),
              }
            ],
          },
          {
            text : this.$t('tuto.login_register.content'),
            // Pas de partage de refs entre composants
            attachTo: { element: document.querySelector('.tuto-highlight-login'), on: 'bottom'},
            classes: 'mt-4',
            modalOverlayOpeningPadding: 10,
            modalOverlayOpeningRadius: 5,
            buttons: [
              {
                action() {
                  return this.cancel();
                },
                text: this.$t('tuto.button.close'),
              },
              {
                action() {
                  return this.next();
                },
                text: this.$t('tuto.button.next'),
              }
            ],
          },
          {
            text: this.$t('tuto.donation.content'),
            attachTo: {element: document.querySelector('.tuto-highlight-donation'), on: 'bottom'},
            classes: 'mt-4 one-button',
            modalOverlayOpeningPadding: 10,
            modalOverlayOpeningRadius: 5,
            buttons: [
              {
                action() {
                  return this.next();
                },
                text: this.$t('tuto.button.finish'),
              }
            ],
          },
        ]
      })

      const urlParams = new URLSearchParams(window.location.search);
      if (!urlParams.get('from') || fromEvent) {
        this.tour.start();
        ['complete', 'cancel'].forEach(eventName => {
          this.tour.on(eventName, event => {
            this.cookieManager.setCookie('bibleVisited', true);
          })
        })
      }
    },
    nextStepTuto(stepId) {
      if(this.tour && this.tour.getCurrentStep() && this.tour.getCurrentStep().id === stepId){
        this.tour.next()
      }
    },
    computeHighlightedVerses() {
      if (this.verse) {
        let verseStartChapter, verseStartNumber, verseEndChapter, verseEndNumber, bookAbbreviation, verses

        if (this.verse.includes('_')) {
          [bookAbbreviation, verses] = this.verse.split('_')

          if (bookAbbreviation) {
            if (this.bookAbbreviation !== bookAbbreviation) {
              this.highlightedVerseIds = []
              return
            }
          }
        } else {
          verses = this.verse
        }

        if (verses.includes('–') || verses.includes('-')) {
          const [verseStart, verseEnd] = this.verse.includes('-') ? verses.split('-') : verses.split('–');
          verseStartChapter = verseStart.split(',')[0];
          verseStartNumber = verseStart.split(',')[1];

          if(verseEnd.includes(',')) {
            verseEndChapter = verseEnd.split(',')[0];
            verseEndNumber = verseEnd.split(',')[1];
          } else {
            verseEndChapter = verseStartChapter;
            verseEndNumber = verseEnd;
          }
        } else {
          verseStartChapter = verses.split(',')[0];
          verseStartNumber = verses.split(',')[1];
          verseEndChapter = verseStartChapter;
          verseEndNumber = verseStartNumber;
        }

        verseStartChapter = parseInt(verseStartChapter).toString()
        verseStartNumber = parseInt(verseStartNumber).toString()
        verseEndChapter = parseInt(verseEndChapter).toString()
        verseEndNumber = parseInt(verseEndNumber).toString()

        const verseStartNumberToCheck = parseInt(verseStartChapter.padStart(4, '0') + verseStartNumber.padStart(4, '0'));
        const verseEndNumberToCheck = parseInt(verseEndChapter.padStart(4, '0') + verseEndNumber.padStart(4, '0'));

        const verseIds = [];

        this.book_verses.forEach((bookVerse) => {
          const splittedVerseId = bookVerse.scope.relative.split(',')
          const currentNumberToCheck = parseInt(splittedVerseId[0].padStart(4, '0') + splittedVerseId[1].padStart(4, '0'));
          if (currentNumberToCheck >= verseStartNumberToCheck && currentNumberToCheck <= verseEndNumberToCheck) {
            verseIds.push('verse-' + bookVerse.scope.relative);
          }
        })

        this.highlightedVerseIds = verseIds;
      }
    }
  },
  mounted() {
    this.fetchContent();
    window.$vueEventBus.$on('locale_change', () => {
      this.fetchContent(true);
      this.$forceUpdate();
    });
    window.$vueEventBus.$on('user_change', () => {
      this.fetchContent(true);
      this.$forceUpdate();
    });
    window.$vueEventBus.$on('close_panel_note', () => {
      this.noteVerseId = null;
      this.isPanelNoteOpen = false;
    })
    window.$vueEventBus.$on('open_panel_note', () => {
      this.isPanelNoteOpen = true;
    })
    window.$vueEventBus.$on('open_note', (note) => {
      this.currentNote = note
      this.isPanelNoteOpen = true;
      setTimeout(() => {
        this.nextStepTuto('note-click-step')
      }, 7000)
    })
    window.$vueEventBus.$on('relaunchTuto', () => {
      this.initTuto(true)
    })
    window.addEventListener('resize', this.text_variant_size);
    var vm = this;
    $(document).on('click mouseenter', '.verse', function () {
      vm.text_variant_size();
    });
    setInterval(function() {
      vm.text_variant_size();
    }, 500)

    if (this.verseId) {
      this.openNotes(this.verseId);
    }
  },
  watch: {
    verse: function(val) {
      this.computeHighlightedVerses()
    },
    uniqueVerses: function (val) {
      this.$nextTick(() => {
        const container = document.querySelector('div.container.content-bible')
        if (container && this.highlightedVerseIds.length > 0) {
          const firstVerseIdToScroll = this.highlightedVerseIds.find((verseId) => {
            return this.$refs[verseId] !== undefined
          })

          if (!firstVerseIdToScroll) {
            return
          }
          const isFirstVerseToScroll = Object.keys(this.$refs)
            .filter(key => {
              return key.startsWith('verse-')
            })
            .findIndex(key => {
              return key === firstVerseIdToScroll
            }) === 0

          if (isFirstVerseToScroll) {
            return
          }

          let target = this.$refs[firstVerseIdToScroll][0]?.$el;
          if (target) {
            let scrollPosition = target.offsetTop - container.offsetTop;
            window.scrollTo({
              top: scrollPosition,
              behavior: 'smooth',
            })
          }
        }
      })
    },
    book_verses: function(val) {
      this.computeHighlightedVerses()
    },
    $route(to, from) {
      if (to.name == 'bible') {
        this.bookAbbreviation = to.params.bookAbbreviation || this.bookAbbreviation;
        this.chapter = to.params.chapter || this.chapter;
        this.verse = to.query.verse;
        this.verseId = to.query.verseId;
        this.fetchContent();
      }

      if (from.name == 'bible' && !to.query.verse && !to.query.verseId) {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        })
      }
    }
  }
}
</script>

<style scoped>
.bible-content-wrapper {
  position: relative;
}

.bible-content-wrapper.chapter-panel-open .content-bible,
.bible-content-wrapper.trad-panel-open .content-bible {
  filter: blur(1px);
}

.bible-content-wrapper.chapter-panel-open:after,
.bible-content-wrapper.trad-panel-open:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: black;
  opacity: 0.3;
  z-index: 5;
}

.content-bible {
  max-width: 100%;
  margin: 0 auto;
  padding: 27px 16px;
}

.content-bible > div {
  max-width: 1008px;
  margin: 0 auto;
}

@media (max-width: 900px) {
  .content-bible {
    margin: 0;
  }
}

.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 770px;
  margin: 40px auto 20px auto;
}

.pagination .arrow-left .btn-box {
  padding-right: 16px;
}

.pagination .arrow-right .btn-box {
  padding-left: 16px;
}

</style>
