<template>
  <div class="popup-modal-wrapper">
    <v-dialog v-model="dialog" persistent max-width="400px" class="poping-modal">
      <v-card>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="close-popup-modal grey darken-1" text @click="dialog = false">{{ $t('ignore') }}</v-btn>
        </v-card-actions>
        <img class="logo-modal light-theme-item" src="/images/logo-black.png" title="Logo" alt="Logo" />
        <img class="logo-modal dark-theme-item" src="/images/logo-white.png" title="Logo" alt="Logo" />
        <v-card-title class="d-block">
          <h1>{{ $t('register title') }}</h1>
          <p>{{ $t('register text') }}</p>
        </v-card-title>
        <v-card-text class="pb-7">
          <v-container class="text-center">
            <a href="https://don.fondationdesmonasteres.org/soutenir-Bible-en-ses-Traditions" target="_blank" class="btn-red">{{$t('make_donation')}}</a>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import CookieManager from "../classes/CookieManager";

export default {
  created() {
    window.setTimeout(() => {
      if(this.cookieManager.getCookie('bibleVisited', false) && this.cookieManager.getCookie('tutoHomeVisited', false) && this.cookieManager.getCookie('sommaireBibleVisited', false)) {
        this.dialog = !localStorage.getItem('token')
      }
    }, 3 * 60 * 1000) // 3 minutes
  },
  data: () => ({
    dialog: false,
    cookieManager: new CookieManager(),
  }),
}
</script>

<style>
  .v-dialog .v-btn span {
    font-size: 16px;
    line-height: 27px;
    color: rgba(0,0,0,0.37);
    cursor: pointer;
    text-transform: capitalize;
    letter-spacing: 0.02em;
  }

  .theme--dark .v-dialog .v-btn span {
    color: rgba(255,255,255,0.5);
  }

  .theme--dark .light-theme-item {
    display: none !important;
  }

  .dark-theme-item {
    display: none !important;
  }

  .theme--dark .dark-theme-item {
    display: block !important;
  }
</style>

<style scoped>
  h1 {
    word-break: normal;
    font-size: 1em;
    text-align: center;
    font-family: 'Merriweather', serif;
  }
  p {
    word-break: normal;
    font-size: 0.8em;
    text-align: center;
    line-height: 1.1em;
  }

  .logo-modal {
    margin: 0 auto;
    display: block;
    width: 50px;
  }

  .v-dialog {
    text-align: center;
  }

  .v-card__title h1 {
    color: black;
    font-weight: bold;
    font-size: 18px;
    line-height: 20px;
    font-family: 'Merriweather', serif;
    text-align: center;
    margin: 20px 0 15px 0;
  }

  .v-card__title p {
    font-size: 18px;
    line-height: 20px;
    margin-bottom: 15px;
    display: block;
  }

  .theme--dark .v-card__title h1 {
    color: white;
  }

</style>