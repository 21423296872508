<template>
  <div class="top-navigation">
      <div class="container">
        <div class="d-flex align-items-center justify-content-lg-between justify-content-center">
          <div class="notes-opener btn-box tuto-highlight-notes" @click="openNotes()" :class="openNotesPanel ? 'active' : ''">{{ $t('notes_title') }}</div>

          <div class="left">
            <div class="dummy"></div>
            <div class="arrow-left">
              <router-link :to="{name: 'bible', params: {bookAbbreviation:prevBook.url_param, chapter: prevChapter}, query: { verse: verse }}" v-if="prevBook && prevChapter" ref="prev" class="btn-box" :title="prevText">
                <svg xmlns="http://www.w3.org/2000/svg" class="chevron-left" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="#1B2847" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M15 6l-6 6l6 6" /></svg>
              </router-link>
            </div>
          </div>

          <div id="bible-nav">
            <div class="chapter-select-container">
              <div class="d-flex align-items-center">
                <div class="tradition-badge badge-color-hover current_tradition mr-2" :title="`${$t('main_tradition')} (${getTraditionObject(mainTradition).fullLabel[locale]})`" :style="`--color-badge:${getTraditionObject(mainTradition).color}`">{{ mainTradition }}</div>
                <h2 class="text-center text-merriweather mb-0" v-if="activeBook">
                  <span class="text-merriweather font-weight-bold">
                    <router-link :to="{name: 'book', params: {bookAbbreviation: bookAbbreviation}}">{{ activeBook.name }}</router-link>
                  </span>
                  <span class="chapter-number chapter-number-toggle" @click="openPanelChapters()">{{ chapter }}</span>
                </h2>
              </div>
            </div>
          </div>

          <div class="right">
            <div class="arrow-right tuto-highlight-next-chapter">
              <router-link :to="{name: 'bible', params: {bookAbbreviation:nextBook.url_param, chapter: nextChapter}, query: { verse: verse }}" v-if="nextBook && nextChapter" ref="next" class="btn-box" :title="nextText">
                <svg style="rotate: 180deg" xmlns="http://www.w3.org/2000/svg" class="chevron-right" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="#1B2847" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M15 6l-6 6l6 6" /></svg>
              </router-link>
            </div>
            <div>
              <div @click="openPanelTraditionConfiguration()" class="traditions traditions-toggle tuto-highlight-tradition-config" :class="openPanel ? 'active' : ''">
                {{ $t('traditions_config_title') }}
                <div class="badges">
                <span class="tradition-badge"
                      v-for="traditionAbbreviation in availableTraditions"
                      :style="activesTraditions.includes(traditionAbbreviation) ? '--color-text-badge: white;--color-badge:' + getTraditionObject(traditionAbbreviation).color+';--full-color-badge:' + getTraditionObject(traditionAbbreviation).color : '--color-badge:' + getTraditionObject(traditionAbbreviation).color">
                  {{ traditionAbbreviation }}
                </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    <PanelTraditionConfiguration v-show="openPanel"  :availableTraditions="availableTraditions" :locale="locale" />
    <PanelChapters v-show="openChaptersPanel" :chapters="chapters" :bookAbbreviation="bookAbbreviation" :currentChapter="currentChapter"/>
  </div>
</template>

<script>
import BestApiService from '../services/BestApiService.js'
import PanelTraditionConfiguration from '../components/PanelTraditionConfiguration';
import PanelChapters from '../components/PanelChapters.vue';

export default {
  //beforeDestroy() {
//    window.$vueEventBus.$off();
//  },
  components: { PanelTraditionConfiguration, PanelChapters },
  computed: {
    verse: function() {
      return this.$route.query.verse
    },
    activesTraditions: function() {
      return this.$store.getters.getActivesTraditions();
    },
    traditions: function() {
      return this.$store.getters.getAllTraditions();
    },
    activeBook: function () {
      return this.toc ? this.toc.books.find(book => book.url_param === this.bookAbbreviation) : null;
    },
    chapters: function () {
      return this.activeBook ? [...Array(this.activeBook.num_chapters).keys()].map(i => i + 1) : [];
    },
    nextBook: function() {
      var index = this.toc ? this.toc.books.indexOf(this.activeBook) : -1;
      let nextBook = index != -1 && index < this.toc.books.length - 1 && this.toc.books[index + 1] ? this.toc.books[index + 1] : null;
      return this.needNextBook ? nextBook : this.activeBook;
    },
    nextChapter: function () {
      var next = parseInt(this.chapter) + 1;
      if (!this.needNextBook) {
        return next;
      }
      return this.nextBook ? 1 : null;
    },
    needNextBook: function() {
      var next = parseInt(this.chapter) + 1;
      return this.activeBook && next > this.activeBook.num_chapters
    },
    nextText: function () {
      var next = parseInt(this.chapter) + 1;
      if (this.activeBook && next <= this.activeBook.num_chapters) {
        return (this.activeBook.url_param == 'Ps' ? this.$t('psalm') : this.$t('chapter')) + ' ' + next;
      }
      return this.nextBook ? this.$t('next book') + ' (' + this.nextBook.name + ')' : '';
    },
    prevBook: function() {
      var index = this.toc ? this.toc.books.indexOf(this.activeBook) : -1;
      let book = index > 0 && this.toc.books[index - 1] ? this.toc.books[index - 1] : null;
      return this.needPrevBook ? book : this.activeBook;
    },
    prevChapter: function () {
      var prev = parseInt(this.chapter) - 1;
      if (!this.needPrevBook && this.activeBook) {
        return Math.min(prev, this.activeBook.num_chapters);
      }
      return this.prevBook ? this.prevBook.num_chapters : null;
    },
    needPrevBook: function() {
      var prev = parseInt(this.chapter) - 1;
      return prev <= 0;
    },
    prevText: function () {
      var prev = parseInt(this.chapter) - 1;
      if (prev > 0 && this.activeBook) {
        return (this.activeBook.url_param == 'Ps' ? this.$t('psalm') : this.$t('chapter')) + ' ' +  Math.min(prev, this.activeBook.num_chapters);
      }
      return this.prevBook ? this.$t('previous book') + ' (' + this.prevBook.name + ')' : '';
    },
  },
  created() {
    this.bookAbbreviation = this.$route.params.bookAbbreviation || this.bookAbbreviation;
    this.chapter = this.$route.params.chapter || this.chapter;
  },
  data() {
    return {
      bookAbbreviation: null,
      chapter: null,
      toc: null,
      openPanel: false,
      openChaptersPanel: false,
      openNotesPanel: false,
      locale: 'fr',
    }
  },
  mounted() {
    this.fetchContent();
    window.$vueEventBus.$on('locale_change', this.fetchContent);
    window.$vueEventBus.$on('user_change', () => {
      this.fetchContent(true);
      this.$forceUpdate();
    });
    window.$vueEventBus.$on('close_panel_note', () => {
      this.openNotesPanel = false;
    });
    window.$vueEventBus.$on('open_panel_note', () => {
      this.openNotesPanel = true;
    });
  },
  methods: {
    fetchContent(wantReload = false) {
      BestApiService.getToc(this.$store, wantReload, (json) => {
        this.toc = json;
        this.refreshTitle();
        this.$forceUpdate();
      });
      this.locale = this.$store.getters.getLocale()
    },
    refreshTitle() {
      $(document).prop('title', this.activeBook ? this.activeBook.name + ' ' + this.chapter : 'La Bible en ses Traditions');
    },
    openPanelTraditionConfiguration() {
      this.openPanel = !this.openPanel;
      document.querySelector('.bible-content-wrapper').classList.toggle('trad-panel-open');
    },
    openPanelChapters() {
      this.openChaptersPanel = !this.openChaptersPanel;
      document.querySelector('.bible-content-wrapper').classList.toggle('chapter-panel-open');
    },
    openNotes() {
      if(this.openNotesPanel) {
        window.$vueEventBus.$emit('close_panel_note');
      } else {
        window.$vueEventBus.$emit('open_panel_note', 'synthese');
      }
    },
    getTraditionObject(tradition) {
      return this.traditions[tradition] ?? this.traditions.default
    }
  },
  props: {
    availableTraditions: {
      type: Array,
      default: () => {
        return []
      }
    },
    currentChapter: {
      type: String|Number,
      default: () => {
        return null
      }
    },
    mainTradition: {
      type: String,
      default: () => {
        return 'V'
      }
    },
  },
  watch: {
    $route(to, from) {
      if (to.name == 'bible') {
        this.bookAbbreviation = to.params.bookAbbreviation || this.bookAbbreviation;
        this.chapter = to.params.chapter || this.chapter;
        this.refreshTitle();
      }
    }
  },
}
</script>

<style>

.mdi-arrow-right,
.mdi-arrow-left {
  color: #ba1416 !important;
  font-size: 17px !important;
}

.theme--dark .mdi-arrow-right,
.theme--dark .mdi-arrow-left {
  color: #fff !important;
}

.dropdown-menu {
  padding: 0 10px !important;
}

.dropdown-menu[x-placement^="top"],
.dropdown-menu[x-placement^="bottom"] {
  transform: translateY(30px) !important;
  width: calc(100vw - 50px) !important;
  top: 15px !important;
  left: 10px !important;
  bottom: auto !important;
  max-width: 545px;
}

.dropup, .dropright, .dropdown, .dropleft {
  position: unset !important;
}

.dropdown-menu {
  z-index: 3 !important;
}

</style>

<style scoped>
.top-navigation {
  background-color: #F4F4F4;
  border-bottom: 1px solid #1B2847;
  position: sticky;
  top: 85px;
  z-index: 10;
}

.top-navigation .left,
.top-navigation .right {
  display: flex;
  gap: 30px;
  align-items: center;
}

@media (max-width: 700px) {
  .top-navigation .left,
  .top-navigation .right {
    gap: 16px;
  }
}

@media (max-width: 520px) {
  .top-navigation .left,
  .top-navigation .right {
    gap: 8px;
  }
}

@media (min-width: 1100px) {
  .top-navigation .left,
  .top-navigation .right {
    min-width: 250px;
  }
}

@media (max-width: 450px) {
  .top-navigation .left {
    gap: 0;
  }
}

@media (min-width: 1100px) {
  .top-navigation .left .dummy {
    width: 170px;
  }
}

.top-navigation .notes-opener {
  position: absolute;
  right: 24px;
  font-size: 14px;
  line-height: 18px;
  font-family: 'Merriweather', serif;
  font-weight: 700;
  padding: 13px 13px;
}

@media (max-width: 520px) {
  .top-navigation .notes-opener {
    padding: 13px 10px;
  }
}

@media (max-width: 1100px) {
  .top-navigation .notes-opener {
    position: relative;
    right: auto;
  }
}

@media (max-width: 450px) {
  .top-navigation .notes-opener {
    position: fixed;
    bottom: 16px;
    right: 16px;
    background: #f4f4f4;
  }
}

.top-navigation .notes-opener.active {
  display: none;
}

.top-navigation .container {
  max-width: 1000px;
}

.top-navigation .traditions {
  font-size: 14px;
  line-height: 18px;
  color: #1B2847;
  font-family: 'Merriweather', serif;
  font-weight: 700;
}

.top-navigation .traditions-toggle {
  font-size: 14px;
  line-height: 18px;
  color: #1B2847;
  font-family: 'Merriweather', serif;
  font-weight: 700;
  padding: 7px 8px 7px 13px;
  border: 1px solid #D0D4DB;
  border-radius: 4px;
  display: flex;
  align-items: center;
  grid-gap: 16px;
  cursor: pointer;
  min-height: 50px;
}

@media (max-width: 1200px) {
  .top-navigation .traditions-toggle {
    flex-direction: column;
    gap: 4px;
    font-size: 13px;
    line-height: 14px;
  }
}

.top-navigation .traditions-toggle:hover,
.top-navigation .traditions-toggle:active,
.top-navigation .traditions-toggle:focus,
.top-navigation .traditions-toggle.active {
  background-color: white;
}

.top-navigation .traditions .badges {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  grid-gap: 2px;
}

@media (min-width: 1200px) {
  .top-navigation .traditions .badges {
    width: 70px;
  }
}

@media (max-width: 520px) {
  .top-navigation .traditions.traditions-toggle {
    min-height: 36px;
    align-items: center;
    justify-content: center;
  }

  .top-navigation .traditions .badges {
    display: none;
  }
}

.top-navigation .traditions .badges  .tradition-badge {
  min-width: 16px;
  height: 16px;
  padding: 0 3px;
}

.chapter-select-container {
  margin-bottom: 0;
}

#bible-nav .dropdown button {
  background: white;
  border: none;
  color: black;
  font-weight: bold;
  font-size: 15px;
  line-height: 17px;
  font-family: 'Merriweather', serif;
  margin-right: 5px;
}

#bible-nav h2 a,
#bible-nav h2 .chapter-number {
  font-size: 16px;
  line-height: 20px;
  color: #1B2847;
  font-family: 'Merriweather', serif;
  font-weight: 700;
  text-decoration: underline;
}

#bible-nav h2 .chapter-number {
  margin-left: 3px;
  cursor: pointer;
}

#bible-nav h2 a:hover,
#bible-nav h2 .chapter-number:hover {
  opacity: 0.7;
}

.chapter-select-container {
  padding-left: 15px;
  padding-right: 15px;
}

.drop-down-books a {
  color: #B40000;
  font-family: 'Merriweather', serif;
}

.drop-down-books a:active,
.drop-down-books a.active {
  background-color: #B40000;
  color: #fff;
}

@media (max-width: 576px) {
  .dropdown-menu .col-12:first-child {
    padding-bottom: 0;
  }

  .dropdown-menu .col-12:last-child {
    padding-top: 0;
  }

  .dropdown-item {
    font-size: 15px;
  }
}

.nav-element {
  display: inline-block;
}

</style>
