<template>
  <v-menu v-model="menu" :close-on-content-click="false" offset-x>
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" class="register-btn btn-red mr-3">{{ $t('register') }}</v-btn>
    </template>
    <template>
      <v-tabs class="elevation-2 panel-register">
        <v-tabs-slider></v-tabs-slider>
        <v-tab href="#tab-create" class="tab-element" v-if="!token">
          {{ $t('create account') }}
        </v-tab>
        <v-tab-item value="tab-create" v-if="!token">
          <v-card flat tile>
            <div class="close-modal close-registration-modal"><svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-x" width="28" height="28" viewBox="0 0 24 24" stroke-width="1.5" stroke="#000000" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M18 6l-12 12" /><path d="M6 6l12 12" /></svg></div>
            <v-card-text>
              <div>
              <div v-if="success === true" class="registration-form-validate">
                <div>
                  <h2>{{ $t('account_created_title') }}</h2>
                  <div v-html="$t('account_created_text')">
                  </div>
                </div>
                <div>
                  <h2>{{ $t('account_donation') }}</h2>
                  <p v-html="$t('account_donation_text')"></p>
                  <a class="btn-red full" href="https://don.fondationdesmonasteres.org/soutenir-Bible-en-ses-Traditions" style="display: inline-block;" target="_blank">{{ $t('make_donation') }}</a>
                </div>
              </div>
              <div v-else class="registration-form-wrapper">
                <div>
                  <h1>{{ $t('register_title') }}</h1>
                  <h2>{{ $t('register_subtitle') }}</h2>
                  <div v-html="$t('register_content')"></div>
                </div>
                <div class="right">
                  <h2>{{ $t('create_account') }}</h2>
                  <div>
                    <div class="alert alert-danger" role="alert" v-for="error in errors" v-html="error"></div>
                  </div>
                  <div>
                    <form @submit="submitForm">
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="lastname">{{ $t('lastname') }}</label>
                            <input name="lastname" id="lastname" type="text" v-model="lastname" class="form-control" :placeholder="$t('lastname_placeholder')" required/>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="firstname">{{ $t('firstname') }}</label>
                            <input name="firstname" id="firstname" type="text" v-model="firstname" class="form-control" :placeholder="$t('firstname_placeholder')" required/>
                          </div>
                        </div>
                      </div>

                      <div class="form-group">
                        <label for="email">{{ $t('email') }}</label>
                        <input name="email" type="email" id="email" v-model="email" class="form-control" :placeholder="$t('email_placeholder')" required/>
                      </div>

                      <div class="row">
                        <div class="col-md-6">

                          <div class="form-group">
                            <label for="password">{{ $t('password_create') }}</label>
                            <input name="first" id="password_first" type="password" v-model="password" class="form-control" :placeholder="$t('password_placeholder')" required/>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="repeated_password">{{ $t('repeated_password') }}</label>
                            <input name="second" id="password_second" type="password" v-model="repeatedPassword" class="form-control" required/>
                          </div>
                        </div>
                      </div>

                      <div class="form-group mt-2">
                        <input name="conditions" id="conditions" v-model="conditions" type="checkbox" required>
                        <label style="color: black" for="conditions">{{ $t('conditions') }} (<a :href="Routing.generate('front_conditions')" target="_blank">{{ $t('see') }}</a>)</label>
                      </div>
                      <div id="recaptchaRegistration" class="g-recaptcha" name="captcha"></div>
                      <button class="btn-red full mt-2">{{ $t('create_account_button') }}</button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </template>
  </v-menu>
</template>

<script>
import Routing from '../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js';
import BestApiService from "../services/BestApiService";
import VerseModalContentClass from "../classes/VerseModalContentClass";

export default {
  created() {
    window.setTimeout(() => this.dialog = !localStorage.getItem('token'), 3 * 60 * 1000) // 3 minutes
  },
  computed: {
    locale() {
      return this.$store.getters.getLocale();
    }
  },
  data: () => ({
    menu: false,
    firstname: null,
    lastname: null,
    email: null,
    password: null,
    repeatedPassword: null,
    conditions: false,
    success: null,
    errors: [],
    sitekey : null,
    displayDonnationLink: false,
    Routing,
  }),
  mounted () {
    window.$vueEventBus.$on('user_change', () => {this.init()});
    window.$vueEventBus.$on('locale_change', () => {
      if(grecaptcha && $('#recaptchaRegistration').length) {
        let iframegrecaptcha = document.querySelector('#recaptchaRegistration iframe')
        let lang = iframegrecaptcha.getAttribute("src").match(/hl=(.*?)&/).pop();

        if(lang !== this.locale) {
          iframegrecaptcha.setAttribute(
              "src",
              iframegrecaptcha.getAttribute("src").replace(
                  /hl=(.*?)&/,
                  'hl=' + this.locale + '&'
              )
          );
        }
      }
    });
    this.init();
  },
  methods: {
    init() {
      if (this.token == '' || !this.token) {
        this.sitekey = this.$store.getters.getRecaptchaSiteKey();
        this.initReCaptcha();
      }
    },
    initReCaptcha() {
      setTimeout(() => {
        if(grecaptcha === 'undefined') {
          this.initReCaptcha();
        } else if($('#recaptchaRegistration').length) {
          grecaptcha.render('recaptchaRegistration', { sitekey: this.sitekey, name: 'recaptchaRegistration', 'hl': this.locale });
        } else {
          this.initReCaptcha();
        }
      }, 1000);
    },
    submitForm(e) {
      e.preventDefault();
      this.success = null;
      this.errors = [];
      var formData = new FormData(e.target);
      var user = {};
      var payment = {};
      var password = {};
      var g_recaptcha_response = '';
      formData.forEach(function (value, key) {
        if (key === 'g-recaptcha-response') {
          g_recaptcha_response = value;
        } else if (['amount', 'frequency', 'amount-other'].includes(key)) {
          payment[key] = value;
        }  else if (['first', 'second'].includes(key)) {
          password[key] = value;
        } else {
          user[key] = value;
        }
      });
      user.payment = payment;
      user.password = password;
      var json = JSON.stringify({ front_user: user, 'g-recaptcha-response': g_recaptcha_response });
      BestApiService.register(json)
          .then(json => {
            this.success = json.success;
            if (json.errors) {
              for (var name in json.errors) {
                if (json.errors.hasOwnProperty(name)) {
                  if (name === 'root') {
                    this.errors.push(error);
                  } else {
                    var field = json.errors[name];
                    for (const error of field) {
                      var $elems = $('#myForm [name="' + name + '"]')
                      if ($elems.length && $elems[0] && typeof $elems[0].setCustomValidity === 'function') {
                        $elems[0].setCustomValidity(error);
                      }
                      this.errors.push(error);
                    }
                    grecaptcha.reset();
                  }
                }
              }
            }
            $('#myForm input[name], #myForm select[name]').on('change', function() {
              $(this)[0].setCustomValidity('')
            });
            if (json.success && json.redirectToPayment) {
              this.displayDonnationLink = true;
            }
          });
    }
  },
  props: {
    user: {
      type: Object,
      default: () => {
        return {}
      }
    },
    token: {
      type: String,
      default: () => {
        return ''
      }
    },
  },
}
</script>

<style>
.v-dialog .v-btn span {
  font-size: 16px;
  line-height: 27px;
  color: rgba(0, 0, 0, 0.37);
  cursor: pointer;
  text-transform: capitalize;
  letter-spacing: 0.02em;
}

.theme--dark .v-dialog .v-btn span {
  color: rgba(255, 255, 255, 0.5);
}

.theme--dark .light-theme-item {
  display: none !important;
}

.dark-theme-item {
  display: none !important;
}

.theme--dark .dark-theme-item {
  display: block !important;
}
</style>

<style scoped>

h1 {
  font-size: 17px;
  font-weight: 700;
  font-family: 'Merriweather', serif;
  margin-bottom: 4px;
  color: black;
}

h2 {
  font-size: 24px;
  font-weight: 700;
  letter-spacing: -0.9px;
  color: #C91B23;
}

p, li {
  font-size: 16px;
  line-height: 20px;
  color: black;
}

label {
  color: black;
  font-weight: bold;
}

.btn-primary {
  min-width: 160px;
  font-weight: bold;
}

.logo-modal {
  margin: 0 auto;
  display: block;
  width: 50px;
}

.v-dialog {
  text-align: center;
}

.v-card__title h1 {
  color: black;
  font-weight: bold;
  font-size: 18px;
  line-height: 20px;
  font-family: 'Merriweather', serif;
  text-align: center;
  margin: 20px 0 15px 0;
}

.v-card__title p {
  font-size: 18px;
  line-height: 20px;
  margin-bottom: 15px;
  display: block;
}

.theme--dark .v-card__title h1 {
  color: white;
}

.close-modal {
  position: absolute;
  right: 16px;
  top: 16px;
  cursor: pointer;
}

</style>