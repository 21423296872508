<template>
  <div class="sommaire-bible container pt-18" v-bind:class = "(book && textsPannel.length > 0)?'has-panel-right':'no-panel-right'">
    <div class="content-left">
      <router-link :to="{name: 'home', query: {testament: this.currentTestament ? this.currentTestament.name : null}}" class="btn-back mb-6 tuto-highlight-back-home">< {{ $t('back') }}</router-link>
      <div class="d-flex flex-wrap align-items-center mb-2">
        <p v-if="currentTestament" class="text-merriweather font-weight-bold mb-0">{{ currentTestament.name }}</p>
        <span v-if="currentTestament" class="text-merriweather font-weight-light px-2" style="font-size: 22px; line-height: 25px">/</span>
        <p v-if="currentTestament" class="text-merriweather font-weight-light mb-0">{{ groupName[locale] }}</p>
      </div>

      <div class="page-content" v-if="!isLoadingSommaire">
        <div v-if="book">
          <h1 class="title-1-merriweather color-green mb-8" :style="`--color-underline-book:${getColorBook(book.url_param)}`">{{ book.name }}</h1>
          <div ref="tuto-highlight-chapters-pericopes">
            <h2 class="title-2-merriweather color-green mb-6" :style="`--color-underline-book:${getColorBook(book.url_param)}`">{{ $t('chapters') }}</h2>
            <div class="chapters">
              <router-link :to="{name: 'bible', params: {bookAbbreviation:bookAbbreviation, chapter: chapter.chapter}}" @click.native="goBook()" v-for="chapter in book.chapters" class="chapter">{{ chapter.chapter }}</router-link>
            </div>
            <div v-if="book.pericopes.length">
              <h2 class="title-2-merriweather color-green pt-10 mt-10 mb-4" :style="`--color-underline-book:${getColorBook(book.url_param)}`">{{ $t('pericopes') }}</h2>
              <div class="grid-pericopes">
                <div v-for="pericope in book.pericopes">
                  <router-link class="text-tiny-merriweather" @click.native="goBook()" :to="{name: 'bible', params: {bookAbbreviation:bookAbbreviation, chapter: getChapterFromScope(pericope.scope.relative)}, query: {verse: getVerseFromScope(bookAbbreviation, pericope.scope.relative)}}">
                    <strong class="text-tiny-merriweather">{{pericope.title}}</strong><br>({{pericope.scope.relative}})
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <p v-else>
        {{ $t('loading') }}
      </p>
    </div>

    <div class="panel-right tuto-highlight-introduction" v-if="book && textsPannel.length > 0">
      <div class="panel-wrapper">
        <div class="panel-summary-subtitle">{{ book.name.toLowerCase() }}</div>
        <div class="panel-summary-title">{{ $t('about') }}</div>
        <div class="panel-text-wrapper">
          <div v-if="!isLoadingPanel">
            <div class="panel-collapse" v-for="(textPannel, index) in textsPannel">
              <p class="panel-collapse-title" @click="toggleText(index, $event)" v-if="textPannel.title">
                <svg xmlns="http://www.w3.org/2000/svg" class="chevron-right" width="20" height="20" viewBox="0 0 24 24" stroke-width="3" stroke="#1B2847" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M9 6l6 6l-6 6" /></svg>
                {{ textPannel.title }}
              </p>
              <div class="panel-collapse-content-wrapper note-content" :data-text-toggled="index" :class="textPannel.title ? 'hide-content' : '' ">
                <div class="panel-collapse-content" v-html="textPannel.content" ></div>
              </div>
            </div>
          </div>
          <p v-else>
            {{ $t('loading') }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BestApiService from '../services/BestApiService.js';
import MediaFetcher from '../classes/MediaFetcher.js';
import { useShepherd } from 'vue-shepherd';
import CookieManager from "../classes/CookieManager";

export default {
  computed: {
    mainTradition: function () {
      return 'V';
    },
    currentBookAbbr() {
      return this.book ? this.book.abbreviation : null;
    },
    currentTestament() {
      return this.toc ? this.toc.parts.find(testament => testament.books.find(book => {
        return book === this.currentBookAbbr
      })) : null;
    },
    groupName() {
      let groupsName = this.$store.getters.getTestamentsGrouping();
      let parts = this.toc.parts

      let groupPart = Object.keys(parts).find(key => parts[key].books.includes(this.currentBookAbbr));
      let grouping = Object.keys(parts[groupPart].groupings).find(key => parts[groupPart].groupings[key].books.includes(this.currentBookAbbr));
      return groupsName[groupPart][grouping];
    }
  },
  created() {
    this.bookAbbreviation = this.$route.params.bookAbbreviation || this.bookAbbreviation;
  },
  data() {
    return {
      bookAbbreviation: null,
      book: null,
      textsPannel: [],
      toc: null,
      isLoadingSommaire: true,
      isLoadingPanel: true,
      contentModals: [],
      currentModalIndex: 0,
      locale: 'fr',
      tour: useShepherd({useModalOverlay: true,
        defaultStepOptions: {
          scrollTo: { behavior: 'smooth', block: 'center' }
        },
      }),
      cookieManager: new CookieManager(),
    }
  },
  methods: {
    fetchContent(wantReload = false) {
      BestApiService.getBookInformations(this.$store, this.bookAbbreviation, wantReload, (json) => {
        this.book = json
        this.refreshTitle();
        this.$forceUpdate();
        this.isLoadingSommaire = false;
      });
      BestApiService.getToc(this.$store, wantReload, (json) => {
        this.toc = json;
        this.$forceUpdate();
      });
      // Used to pre-fetch for opening notes
      BestApiService.getSynthNotes(this.$store, wantReload, () => {
      });
      BestApiService.getIntroductions(this.$store, wantReload, () => {
        let textIntroBook = this.$store.getters.getIntroductionByUrlParam(this.bookAbbreviation)?.text

        if (!textIntroBook) {
          // No introduction.
          this.isLoadingPanel = false;
          return;
        }

        const domParser = new DOMParser();
        const introEl = domParser.parseFromString(textIntroBook, 'text/html').body;
        const mediaFetcher = new MediaFetcher(this.$store);

        mediaFetcher.insertMediaElements(introEl).then(() => {

          // Group level 4 headings

          this.textsPannel = [];
          let groupUnderH4 = '';
          let currentH4 = ''
          introEl.childNodes.forEach(elementDom => {
            if (elementDom.tagName === 'H4') {
              if (currentH4 !== '' || groupUnderH4 !== '') { // Si on a un groupement par h4 ou qu'on veut afficher le texte si le premier contenu n'est pas un h4
                this.textsPannel = [...this.textsPannel, {
                  title: currentH4?.innerText,
                  content: groupUnderH4,
                }];
                currentH4 = elementDom;
                groupUnderH4 = '';
              } else {
                currentH4 = elementDom;
              }
              // On groupe les éléments sous le h4
            } else {
              groupUnderH4 += elementDom.outerHTML
            }
          })

          if (groupUnderH4 !== '') {
            this.textsPannel = [...this.textsPannel, {
              title: currentH4.innerText,
              content: groupUnderH4,
            }];
          }
        }).then(() => {
          this.isLoadingPanel = false
        });

        setTimeout(() => {this.initTuto()}, 300);
      });
      this.locale = this.$store.getters.getLocale();
    }, getColorBook(bookAbbreviation) {
      let group = this.$store.getters.getColorBook(bookAbbreviation);
      return group?.color;
    }, goBook() {
      this.nextStepTuto('chapter-pericope-step')
    },refreshTitle() {
      $(document).prop('title', this.book ? this.book.name : 'La Bible en ses Traditions');
    }, toggleText(index, event) {
      let divToToggle = document.querySelector(`[data-text-toggled="${index}"]`);
      event.target.classList.toggle('collapse-open');
      divToToggle.classList.toggle('hide-content');
    }, getChapterFromScope(scope) {
      let startPericope = "";

      if(scope.indexOf('–') != -1){
        startPericope = scope.substring(0, scope.indexOf('–'));
      } else{
        startPericope = scope;
      }
      let indexComa = startPericope.indexOf(',')
      return startPericope.substring(0, indexComa);
    }, getVerseFromScope(bookAbbreviation, scope) {
      return bookAbbreviation + '_' + scope;
    }, initTuto(fromEvent = false) {
      if(this.cookieManager.getCookie('sommaireBibleVisited', false)) {
        return;
      }

      setTimeout(() => {
        let elementFocusOn = document.querySelector(".tuto-highlight-introduction")
        if(elementFocusOn) {
          this.tour.addStep(
              {
                title: this.$t('tuto.introduction.title'),
                text: this.$t('tuto.introduction.content'),
                classes: 'ml--2',
                attachTo: {element: elementFocusOn, on: 'left-start'},
                buttons: [
                  {
                    action() {
                      return this.cancel();
                    },
                    text: this.$t('tuto.button.close'),
                  },
                  {
                    action() {
                      return this.next();
                    },
                    text: this.$t('tuto.button.next'),
                  }
                ],
              }
          )
        }
         this.tour.addStep({
           text: this.$t('tuto.chapter_pericope.content'),
           attachTo: {element: this.$refs["tuto-highlight-chapters-pericopes"], on: 'right-start'},
           scrollTo: false,
           classes: 'ml-4',
           modalOverlayOpeningPadding: 10,
           modalOverlayOpeningRadius: 5,
           buttons: [
             {
               action() {
                 return this.cancel();
               },
               text: this.$t('tuto.button.close'),
             },
           ],
           id: 'chapter-pericope-step',
         })

        const urlParams = new URLSearchParams(window.location.search);
        if (!urlParams.get('from') || fromEvent) {
          this.tour.start();
          ['cancel'].forEach(eventName => {
            this.tour.on(eventName, event => {
              this.cookieManager.setCookie('sommaireBibleVisited', true);
              this.cookieManager.setCookie('bibleVisited', true);
            })
          })
              ['complete'].forEach(eventName => {
            this.tour.on(eventName, event => {
              this.cookieManager.setCookie('sommaireBibleVisited', true);
              this.cookieManager.setCookie('bibleVisited', true);
            })
          })
        }
      }, 200)
    }, nextStepTuto(stepId) {
      if(this.tour && this.tour.getCurrentStep() && this.tour.getCurrentStep().id === stepId){
        this.tour.next()
      }
    }
  },
  mounted() {
    this.fetchContent();
    window.$vueEventBus.$on('locale_change', this.fetchContent);
    window.$vueEventBus.$on('user_change', () => {
      this.fetchContent(true);
      this.$forceUpdate();
    });
    window.$vueEventBus.$on('relaunchTuto', () => {
      this.initTuto(true)
    })
  },
  watch: {
    $route(to, from) {
      if (to.name === 'book') {
        this.bookAbbreviation = to.params.bookAbbreviation || this.bookAbbreviation;
        this.refreshTitle();
      }
    }
  },
}
</script>

<style scoped>
  .title-1-merriweather.color-green:after {
    background-color: var(--color-underline-book);
  }

  .title-2-merriweather.color-green {
    color: var(--color-underline-book);
  }

  .hide {
    display: none;
  }

  .grid-pericopes {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 16px;
  }

  @media (max-width: 900px) {
    .grid-pericopes {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media (max-width: 600px) {
    .grid-pericopes {
      grid-template-columns: 1fr;
    }
  }

  .grid-pericopes a {
    color: #1B2847;
  }
</style>
