<template>
  <div class="panel-right notes-panel" :class="isOpen ? 'open' : ''">
    <div class="panel-heading">
      Notes
      <div class="close-panel" @click="closePanel()"></div>
    </div>
    <div class="panel-wrapper">
      <div v-if="isLoading">
        {{ $t('loading') }}
      </div>
      <div v-else class="panel-text-wrapper">
        <ul class="panel-nav">
          <li @click="changeCurrentPanel('note')" :class="currentPanel === 'note' ? 'active' :''">{{ $t('comments') }}</li>
          <li @click="changeCurrentPanel('synthese')" :class="currentPanel === 'synthese' ? 'active' :''">{{ $t('synthesis') }}</li>
        </ul>
        <div v-show="currentPanel === 'note'" class="note-content">
          <div v-if="notes.length">
            <div v-for="(rubrics, registerName) in registers" v-if="getNotesByRegister(rubrics).length">
              <p class="notes-title" :style="'color:' + getFullNameRegister(registerName).color">{{ getFullNameRegister(registerName).label[locale] }}</p>
              <div v-for="(rubricName, rubricAbbr) in rubrics" v-if="getNotesByRubric(rubricAbbr).length">
                <p class="rubrique-name" :style="'--color-register:' + getFullNameRegister(registerName).color">{{ rubricName }}</p>
                <div class="note-content panel-collapse" v-for="note in getNotesByRubric(rubricAbbr)" :ref="'note_'+note.id">
                  <p class="panel-collapse-title note-title" @click="changeOpenNote($event, openNote === note.id ? null : note.id)" :class="openNote === note.id ? 'collapse-open' : ''">
                    <span class="chevron-icon">
                      <svg xmlns="http://www.w3.org/2000/svg" class="chevron-right" width="20" height="20" viewBox="0 0 24 24" stroke-width="3" stroke="#1B2847" fill="none" stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                        <path d="M9 6l6 6l-6 6" />
                      </svg>
                    </span>
                    <span class="note-title-text" v-html="note.title"></span> ({{ note.scope.relative }})
                    <span @click.stop="copyNoteLink(note.id)" class="copy-icon">
                     <svg xmlns="http://www.w3.org/2000/svg" class="link-icon" viewBox="0 0 24 24" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" title="Copier le lien">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                        <path d="M10 14a3.5 3.5 0 0 1 0 -5l4 -4a3.5 3.5 0 0 1 5 5l-1.5 1.5" />
                        <path d="M14 10a3.5 3.5 0 0 1 0 5l-4 4a3.5 3.5 0 0 1 -5 -5l1.5 -1.5" />
                    </svg>
                    </span>
                  </p>
                  <div v-show="openNote === note.id">
                    <p style="color: #b40000">{{note.lemma}}</p>
                    <div class="note-paragraphs" v-html="note.text"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p v-else-if="activeVerse === null">
            {{ $t('no_note_selected') }}
          </p>
          <div v-else>
            {{ $t('no_notes') }}
          </div>
        </div>
        <div v-show="currentPanel === 'synthese'">
          <div class="alphabet-letter-nav">
            <div v-for="(synthNotesByLetter, letter) in notesByLetter" :key="`alphabet-letter-${letter}`">
              <span @click="scrollToLetter(letter)">{{letter}}</span>
            </div>
          </div>

          <div v-for="(synthNotesByLetter, letter) in notesByLetter" :key="letter" :ref="`notes-in-letter-${letter}`">
            <p class="alphabet-letter">{{ letter }}</p>
            <div class="note-content panel-collapse" v-for="note in synthNotesByLetter" :ref="'synth_note_' + note.id">
              <p class="panel-collapse-title note-title" @click="changeOpenNote($event, openNote === note.id ? null : note.id)" :class="openNote === note.id ? 'collapse-open' : ''">
                <svg xmlns="http://www.w3.org/2000/svg" class="chevron-right" width="20" height="20" viewBox="0 0 24 24" stroke-width="3" stroke="#1B2847" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M9 6l6 6l-6 6" /></svg>
                <span v-html="note.title"></span>
                <span @click.stop="copyNoteLink(note.id)" class="copy-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" class="link-icon" viewBox="0 0 24 24" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" title="Copier le lien">
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                      <path d="M10 14a3.5 3.5 0 0 1 0 -5l4 -4a3.5 3.5 0 0 1 5 5l-1.5 1.5" />
                      <path d="M14 10a3.5 3.5 0 0 1 0 5l-4 4a3.5 3.5 0 0 1 -5 -5l1.5 -1.5" />
                  </svg>
                </span>
              </p>
              <div v-show="openNote === note.id" class="note-paragraphs">
                  <div v-html="note.text"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="margsRefs.length" class="bottom-sticky-collapse">
      <div v-for="margRef in margsRefs">
        <p v-html="margRef.text"></p>
      </div>
    </div>
  </div>
</template>

<script>
import BestApiService from '../services/BestApiService.js';
import MediaFetcher from '../classes/MediaFetcher.js';
import Note from './Note';

export default {
  components: {Note},
  computed: {
    notesByLetter: function () {
      let notesByLetters = {};
      for (let i = 65; i <= 90; i++) {
        let letter = String.fromCharCode(i);
        notesByLetters[letter] = this.synthNotes.filter(note => {
          let span = document.createElement('span')
          span.innerHTML = note.title;
          return span.innerText.normalize('NFD').replace(/[\u0300-\u036f]/g, '').startsWith(letter)
        });
      }
      return notesByLetters;
    },
  },
  data() {
    return {
      registers: {},
      panel: 0,
      currentPanel: 'note',
      openNote: null,
      openEchosBiblique: false,
      synthNotes: [],
      isOpen: false,
      locale: 'fr',
      noteId: this.$route.query.noteId || null,
      verseId: this.$route.query.verseId || null,
      currentAutomaticOpennedNote: null,
      isLoading: true,
    }
  },
  methods: {
    copyNoteLink(noteId) {
      const url = new URL(window.location.href);
      url.searchParams.delete('verse');
      url.searchParams.set('noteId', noteId);
      url.searchParams.set('verseId', this.activeVerse ?? 0);

      navigator.clipboard.writeText(url.toString()).then(() => {
      }).catch(err => {
        console.error('Erreur lors de la copie du lien : ', err);
      });

      alert('Le lien de la note a été copié')
    },
    openNoteById(noteId) {
      document.addEventListener('scroll-done', () => {
        const noteExistsInSynthese = this.synthNotes.some(note => note.id === noteId);
        if (noteExistsInSynthese) {
          this.currentPanel = 'synthese';
        }

        const noteElement = this.$refs[`note_${noteId}`] || this.$refs[`synth_note_${noteId}`];
        if (noteElement && noteElement[0] && this.currentAutomaticOpennedNote != noteId) {
          this.$nextTick(() => {
            noteElement[0].scrollIntoView({ behavior: 'smooth' });
            const clickEvent = new MouseEvent('click', {
              view: window
            });
            this.currentAutomaticOpennedNote = noteId;
            noteElement[0].querySelector('.panel-collapse-title').dispatchEvent(clickEvent);
          });
        }
      })
    },
    getNotesByRubric: function (rubric) {
      if (!rubric) {
        return this.notes;
      }

      return this.notes.filter(note => rubric === note.type);
    },
    getNotesByRegister: function (rubricsInRegister) {
      if (!rubricsInRegister) {
        return this.notes;
      }

      return this.notes.filter(note => rubricsInRegister[note.type]);
    },
    fetchContent(wantReload = false) {
      BestApiService.getRubrics(this.$store, wantReload,(registers) => {
        return this.registers = registers[this.testament]
      });

      BestApiService.getSynthNotes(this.$store, wantReload, () => {
        this.synthNotes = this.$store.getters.getSynthNotes();
      });

      this.locale = this.$store.getters.getLocale();

    },
    format(text) { // fix hrefs
      var $result = $('<div />').append($(text));
      $result.find('a').each(function () {
        const ref = $(this).attr('data-scope').split('.')[0];
        const matches = ref.match(/\d+$/);
        const chapter = matches ? matches[0] : '1';
        const book = ref.replace(/\d+$/, '')
        $(this).attr('href', '/bible/' + book + '/' + chapter);
      });
      return $result.prop('outerHTML');
    },
    changeOpenNote(event, noteId) {
      this.openNote = noteId;
      window.$vueEventBus.$emit('open_note', this.notes.find(note => note.id === noteId));

      if (noteId) {
        const contentEl = event.target.closest('.note-content');
        if (contentEl) {
          const mediaFetcher = new MediaFetcher(this.$store);
          mediaFetcher.insertMediaElements(contentEl.querySelector('.note-paragraphs'));
        }
      }

      setTimeout(() => {
        if(event.target.closest('.panel-collapse-title')){
          event.target.closest('.panel-collapse-title').scrollIntoView({behavior: "smooth"});
          }}, 50)

    },
    changeCurrentPanel(panel) {
      this.currentPanel = panel;
      this.openNote = null;
    },
    closePanel() {
      window.$vueEventBus.$emit('close_panel_note');
    },
    getFullNameRegister(registerName) {
      return this.$store.getters.getRegistersNames()[registerName] ?? registerName;
    },
    scrollToLetter(letter) {
      setTimeout(() => {
        const targetElement = this.$refs['notes-in-letter-'+letter][0];
        const stickyElementHeight = document.querySelector('.notes-panel .alphabet-letter-nav').offsetHeight;
        const offset = targetElement.offsetTop - stickyElementHeight - 16;
        const panelWrapper = document.querySelector('.notes-panel .panel-wrapper');

        panelWrapper.scrollTo({
          top: offset,
          behavior: "smooth"
        });
      }, 50);

    }
  },
  watch: {
    isOpen() {
      if (this.noteId) {
        this.$nextTick(() => {
          this.openNoteById(this.noteId);
        })
      }
    },
  },
  mounted() {
    this.fetchContent();
    window.$vueEventBus.$on('locale_change', () => {
      this.fetchContent();
      this.$forceUpdate();
    });
    window.$vueEventBus.$on('user_change', () => {
      this.fetchContent(true);
      this.$forceUpdate();
    });
    window.$vueEventBus.$on('open_panel_note', (noteCategory) => {
      this.isOpen = true;
      this.currentPanel = noteCategory;
    })
    window.$vueEventBus.$on('end_loading_panel_note', () => {
      this.isLoading = false;
    })
    window.$vueEventBus.$on('close_panel_note', () => {
      this.isOpen = false;
    })
  },
  name: 'Notes',
  props: {
    notes: {
      type: Array,
      default: () => [],
    },
    margsRefs: {
      type: Array,
      default: () => [],
    },
    testament: {
      type: String,
      default: () => 'AT'
    },
    activeVerse: {
      type: String,
      default: () => null
    }
  },
}
</script>

<style>
.notes-container .v-expansion-panel .v-expansion-panel-header__icon {
  transition: 0.5s;
}

.notes-container .v-expansion-panel.v-expansion-panel--active .v-expansion-panel-header .v-expansion-panel-header__icon {
  transform: rotate(180deg);
}

.notes-container .v-expansion-panel {
  margin-top: 0 !important;
}

.notes-container .v-expansion-panel .v-expansion-panel-header {
  min-height: auto;
  padding-top: 11px;
  padding-bottom: 11px;
}

.notes-container .v-expansion-panel.mt-6 {
  margin-top: 0 !important;
}
</style>

<style scoped>

* {
  font-family: 'Arimo', sans-serif;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.rubrique-name {
  color: var(--color-register, black);
}

.note-modal {
  border-radius: 0 !important;
}

.header-note {
  background: rgba(96, 93, 83, 0.11);
  padding: 0 36px 10px 36px !important;
  position: relative;
}

.header-note:after {
  content: '';
  display: block;
  clear: both;
}

.header-note .note-info {
  float: left;
  padding: 0 6px 0 14px;
  width: calc(100% - 50px);
}

.header-note .note-info .ref-note {
  font-size: 13px;
  line-height: 17px;
  font-style: italic;
  font-weight: bold;
  margin-bottom: 2px;
}

.header-note > button {
  float: left;
  width: 22px;
  padding-top: 10px;
}

.notes-container {
  width: 95%;
  margin: auto;
  padding-top: 10px;
}

.v-slide-group__prev {
  display: none;
}

.note-title-text {
  margin-right: 10px;
}
.link-icon {
  margin-left: auto;
  cursor: pointer;
  right: 0;
  transform: none !important;
}

.link-icon {
  width: 20px;
  height: 20px;
  stroke: grey;
  transition: stroke 0.3s;
  cursor: pointer;
}

.link-icon:hover {
  stroke: black;
}

.link-icon {
  width: 20px;
  height: 20px;
  stroke: grey;
  transition: stroke 0.3s;
}
.link-icon:hover {
  stroke: black;
}
</style>

