<template>
  <div class="greetings-modal" v-if="showModal">
    <div class="greetings-modal-content">
      <div class="text-right">
        <button class="x-button" @click="closeModal">
          <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" stroke-width="1.5" stroke="#000000" fill="none" stroke-linecap="round" stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
            <path d="M18 6l-12 12" />
            <path d="M6 6l12 12" />
          </svg>
        </button>
      </div>

      <img src="/images/bibleart-logo.svg" alt="" class="mb-6">

      <h2 class="text-center">Bienvenue !</h2>

      <p><strong>Vous trouverez ici toutes les introductions, traductions et notes présentes sur notre application mobile, et même davantage :</strong></p>
      <p><strong>– Des milliers de références</strong> dans la Bible, pour entendre tous les harmoniques et les jeux d’échos dans les Écritures</p>
      <p><strong>– la possibilité de consulter plusieurs versions</strong> du même texte biblique, pour entendre la Parole de Dieu comme une polyphonie,</p>
      <p><strong>– la possibilité de consulter des milliers de notes</strong> exégétiques dans 29 disciplines liées aux sciences bibliques.</p>

      <p class="text-center mb-2"><strong>Pour profiter de tout cela :</strong></p>

      <div class="text-center">
        <button @click="closeModal" class="btn-red register-btn">Je crée mon profil</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showModal: false,
    };
  },
  methods: {
    closeModal() {
      this.showModal = false;
      window.$vueEventBus.$emit('relaunchTuto');
    },
  },
  mounted() {
    // Check for the 'from' parameter in the URL
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get('from') === 'bible-art') {
      this.showModal = true;
    }
  },
};
</script>

<style scoped>
  .greetings-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }

  .greetings-modal-content {
    background: white;
    border-radius: 8px;
    overflow: hidden;
    width: 576px;
    max-width: 90vw;
    padding: 53px 94px 57px 94px;
    position: relative;
    box-shadow: 0 8px 32px #0000005F;
  }

  @media (max-width: 700px) {
    .greetings-modal-content {
      padding: 70px 24px 34px 24px;
    }
  }

  .greetings-modal-content:before,
  .greetings-modal-content:after {
    content: '';
    position: absolute;
    bottom: 0;
    height: 12px;
    left: 0;
    right: 0;
    background-image: url("/images/bandeau-bible-art.png");
    background-size: cover;
    background-position: bottom center;
  }

  .greetings-modal-content:after {
    top: 0;
    bottom: auto;
    background-image: url("/images/bandeau-bible-art.png");
    transform: scaleX(-1);
  }

  h2 {
    color: #1B2847;
    font-size: 18px;
    line-height: 22px;
    font-family: 'Merriweather', serif;
    font-weight: 900;
    margin-bottom: 24px;
  }

  p {
    color: #1B2847;
    font-size: 14px;
    line-height: 18px;
  }

  .x-button {
    position: absolute;
    top: 24px;
    right: 24px;
  }

  img {
    width: 140px;
    max-width: 100%;
    display: block;
    margin: 0 auto;
  }

</style>
