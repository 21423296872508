<template>
  <div class="note" v-if="note">
    <v-expansion-panel-header v-html="title" />
    <v-expansion-panel-content class="note-content">
      <div v-html="text" @click="handleClick($event)"></div>
    </v-expansion-panel-content>
    <v-dialog v-model="dialog" max-width="100%" max-height="100%">
      <div style="text-align: center; background: #000;">
        <img :src="activeImageUrl" alt="image" style="width: auto; max-width: 100%" />
      </div>
    </v-dialog>
  </div>
</template>

<script>
export default {
  computed: {
    text: function() {
      var result = $('<div />').append($(this.note.text));
      result.find('iframe').each(function () {
        $(this).removeAttr('width').removeAttr('height');
      });
      return result.prop('outerHTML');
    },
    title: function() {
      var div = document.createElement('div');
      div.innerHTML = this.note.title;
      return div.textContent || div.innerText || '';
    },
  },
  data: () => ({
    dialog: false,
    activeImageUrl: '',
  }),
  methods: {
    handleClick(e) {
      if (e.target.matches('img')) {
        this.activeImageUrl = e.target.getAttribute('src');
        this.dialog = true;
      }
    },
  },
  name: 'Note',
  props: {
    defaultTitle: {
      type: String,
      default: () => '',
    },
    note: {
      type: Object,
      default: () => {},
    },
  },
}
</script>

<style>

  .theme--light.v-expansion-panels .v-expansion-panel-header .v-expansion-panel-header__icon .v-icon{
    color: #B40000 !important;
    font-size: 30px !important;
  }

  .v-expansion-panel:before {
    box-shadow: none !important;
  }

  .v-expansion-panel:not(:first-child):after {
    border: none !important;
  }

  .v-application .v-expansion-panel {
    margin-top: 0!important;
  }

</style>

<style scoped>

  * {
    font-family: 'Arimo', sans-serif;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }

  .note-content {
    display: initial;
    text-align: initial;
  }

  .note-content >>> .note-container {
    padding: 5px 20px;
  }

  .note-content >>> .note-container * {
    max-width: 100%;
  }

  .note-content >>> .title {
    font-size: inherit !important;
    font-weight: inherit !important;
  }

  .note-content >>> figure {
    display: block !important;
    max-width: 90% !important;
    text-align: center;
    margin: auto;
  }

  .note-content >>> img {
    max-width: 90% !important;
    text-align: center;
    margin: auto;
    cursor: pointer;
  }

  .note-content >>> h1 {
    font-size: 160%;
  }

  .note-content >>> h2 {
    font-size: 150%;
  }

  .note-content >>> h3 {
    font-size: 140%;
  }

  .note-content >>> h4 {
    font-size: 130%;
  }

  .note-content >>> h5 {
    font-size: 120%;
  }

  .note-content >>> h6 {
    font-size: 110%;
  }

  .v-expansion-panel-header {
    color: #B40000;
    font-size: 14px;
    line-height: 18px;
    font-weight: bold;
  }

</style>
