<template>
  <v-app id="app">
    <navigation/>
    <v-main>
      <router-view class="page"/>
    </v-main>
    <RegisterModal/>
    <ModalGreetings/>
    <DraggableModal v-for="content in contentModals" :modalContent="content" :index="content.customIndex"
                    :mainTradition="mainTradition" />
    <Footer :is-re-render-footer="false"/>
    <a href="https://docs.google.com/forms/d/e/1FAIpQLSdXMYobL8xgihYS7IXr-pQdFmHnSY1dQDF7NApg7XUCs3Vaig/viewform?usp=pp_url" :title="$t('open_google_form')" target="_blank" class="flying-cta" data-toggle="tooltip" data-placement="top" data-html="true">
      <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-message-2" width="28" height="28" viewBox="0 0 24 24" stroke-width="1.5" stroke="#ffffff" fill="none" stroke-linecap="round" stroke-linejoin="round">
        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
        <path d="M8 9h8" />
        <path d="M8 13h6" />
        <path d="M9 18h-3a3 3 0 0 1 -3 -3v-8a3 3 0 0 1 3 -3h12a3 3 0 0 1 3 3v8a3 3 0 0 1 -3 3h-3l-3 3l-3 -3z" />
      </svg>
    </a>
  </v-app>
</template>

<script>
import Navigation from './components/Navigation';
import RegisterModal from './components/RegisterModal';
import SynthModalContentClass from "./classes/SynthModalContentClass";
import NoteModalContentClass from "./classes/NoteModalContentClass";
import BibRefModalContentClass from "./classes/BibRefModalContentClass";
import VerseModalContentClass from "./classes/VerseModalContentClass";
import BestApiService from "./services/BestApiService";
import ModalGreetings from "./components/ModalGreetings.vue";
import Footer from "./components/Footer.vue";
import DraggableModal from "./components/DraggableModal.vue";

export default {
  components: {DraggableModal, Navigation, RegisterModal, ModalGreetings, Footer},
  computed: {
    mainTradition: function () {
      return 'V';
    },
  },
  data() {
    return {
      contentModals: [],
      currentModalIndex: 0,
    }
  },
  mounted() {
    this.fetchUser();
    $('[data-toggle="tooltip"]').tooltip()
    document.addEventListener('click',  (event) => {
      let target = event.target
      if (target.closest('a.xref')) {
        event.preventDefault()
        let clickableElement = target.closest('a.xref');
        let modalContent = new VerseModalContentClass(clickableElement.dataset.scope,this.$store)
        this.openReferenceModal(modalContent);

      } else if (target.closest('a.synth_link')) {
        event.preventDefault()
        let clickableElement = target.closest('a.synth_link');
        let modalContent = new SynthModalContentClass(clickableElement.dataset.id, this.$store)
        this.openReferenceModal(modalContent);

      } else if (target.closest('a.note_xref')) {
        event.preventDefault()
        let clickableElement = target.closest('a.note_xref');
        let scope = clickableElement.dataset.scope;
        if(!scope) {
          scope = clickableElement.getAttribute('href').replace('#'+clickableElement.dataset.rubric, '');
        }
        let modalContent = new NoteModalContentClass(scope, this.$store, clickableElement.dataset.rubric)
        this.openReferenceModal(modalContent);

      } else if (target.closest('a.bibref_link')) {
        event.preventDefault()
        let clickableElement = target.closest('a.bibref_link');
        let modalContent = new BibRefModalContentClass(clickableElement.dataset.id, this.$store)
        this.openReferenceModal(modalContent);
      }
    })

    window.$vueEventBus.$on('close_reference_modal', (modalContentToClose) => {
      let contentAlreadyOpenIndex = this.contentModals.findIndex(modal => modal.getUniqueIdBetweenNotes() === modalContentToClose.getUniqueIdBetweenNotes());
      if(contentAlreadyOpenIndex >= 0) {
        this.contentModals[contentAlreadyOpenIndex].display = false
      }
    })

    window.$vueEventBus.$on('user_change', () => {
        this.contentModals.forEach(contentModal => contentModal.reloadContent())
    });
    window.$vueEventBus.$on('synth_notes', () => {
        this.contentModals.forEach(contentModal => contentModal.reloadContent())
    });
    window.$vueEventBus.$on('introductions', () => {
        this.contentModals.forEach(contentModal => contentModal.reloadContent())
    });
    window.$vueEventBus.$on('openModal', (contentModal) => {
        this.openReferenceModal(contentModal);
    });
  },
  methods: {
    fetchUser() {
      if(this.$store.getters.getToken() && !this.$store.getters.getUser()) {
        BestApiService.fetchUser()
            .then(json => {
              if (json.status == 'success') {
                let user = json.user;
                this.$store.commit('SET_USER', user);
                this.$store.commit('SET_API_TOKEN', json.token_content);
            }
        });
      }
    },
    openReferenceModal(modalContentToOpen) {
      let contentAlreadyOpenIndex = this.contentModals.findIndex(modal => modal.getUniqueIdBetweenNotes() === modalContentToOpen.getUniqueIdBetweenNotes());
      if(contentAlreadyOpenIndex >= 0) {
        modalContentToOpen = this.contentModals[contentAlreadyOpenIndex];
        modalContentToOpen.display = true;
      } else {
        this.contentModals.push(modalContentToOpen)
        modalContentToOpen.customIndex = this.currentModalIndex;
        this.currentModalIndex ++;
      }
      let allModals = Array.from(document.querySelectorAll('.draggable-modal'))
      allModals.sort((el1, el2) => (parseInt(el1.style['z-index']) < parseInt(el2.style['z-index']) ? 1 : -1));
      let lastZindex = allModals[0] ? parseInt(allModals[0].style['z-index']) : 900;

      modalContentToOpen.customZIndex = (lastZindex + 1);
    }
  },
}
</script>

<style>

* {
  font-family: 'Arimo', sans-serif;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body.panel-open {
  overflow: hidden;
}

@media (max-width: 1200px) {
  body.panel-open {
    height: 100vh;
  }
}

header.v-app-bar {
  z-index: 20px;
}
.ml--2 {
  margin-left: -0.5rem !important;
}
.ml--4 {
  margin-left: -1.5rem !important;
}

.shepherd-element[data-popper-placement=bottom-start]>.shepherd-arrow{
  right: 50% !important;
  left: auto !important;
}

.shepherd-element[data-popper-placement=right-start]>.shepherd-arrow,
.shepherd-element[data-popper-placement=left-start]>.shepherd-arrow{
  bottom:50% !important;
  top: auto !important;
}

.shepherd-element.shepherd-has-title[data-popper-placement^=bottom]>.shepherd-arrow:before {
  background-color: #FFCC02 !important;
}

.shepherd-element {
  .shepherd-arrow:before{
    background-color: #FFCC02;
  }
  .shepherd-button {
    background: #FFCC02;
    color: black;
    &:not(:disabled):hover {
      background: #FFCC02;
      color: black;
    }
  }
}
.shepherd-element .shepherd-button {
  background: #FFCC02;
  color: black;
}
.shepherd-element .shepherd-button:not(:disabled):hover {
  background: #FFCC02;
  color: black;
}

.shepherd-content{
  padding: 10px 20px !important;
  background-color: #FFCC02;
  .shepherd-header {
    background: #FFCC02 !important;
    display: block;
    border-bottom: 1px solid white;
    .shepherd-title{
      display: block;
      width: 100%;
      font-weight: bold;
      text-align: center;
      font-size : 1.2em;
    }
  }
  .shepherd-text {
    margin: 10px 0 20px 0;
    text-align: center;
  }
  .shepherd-footer {
    display: flex;
    justify-content: space-between;
  }
}

.pt-18 {
  padding-top: 72px
}

.v-main {
  padding: 85px 0 0 !important;
}

.v-menu__content {
  box-shadow: none;
  left: 0;
  width: 100%;
  max-width: 100%;
  top: 85px !important;
  z-index: 1000 !important;
  height: calc(100vh - 85px);
  max-height: unset !important;
  background: rgb(0 0 0 / 40%);
  padding: 24px;
  align-items: center;
  justify-content: center;
}

.v-menu__content .v-tabs {
  margin: 0 auto;
  max-width: 700px;
}

.v-menu__content .v-tabs.panel-register {
  max-width: 700px;
}

.v-menu__content .v-tabs.panel-login .v-tabs-bar,
.v-menu__content .v-tabs.panel-register .v-tabs-bar {
  display: none;
}

.v-menu__content .v-tabs.v-tabs-large .v-tabs-slider-wrapper {
  left: 50% !important;
}

.v-menu__content .v-tabs p {
  text-align: left;
}

.v-menu__content .v-tabs-bar__content .v-tab {
  width: 50%;
  max-width: unset;
  letter-spacing: unset;
  font-family: 'Merriweather', serif;
  font-size: 16px;
}

.v-menu__content .v-tabs-bar__content .v-tab .v-ripple__container {
  display: none !important;
}

.v-menu__content .v-tabs-bar__content .v-tab.v-tab--active {
  background: #e5e2e2;
}

.login-form-wrapper {
  padding: 40px;
}

input.form-control {
  font-size: 14px;
  padding: 12px 12px;
  height: auto;
}

.registration-form-wrapper {
  padding: 20px;
}

.registration-form-wrapper .right h2 {
  padding-top: 20px;
}

.registration-form-wrapper label {
  color: #C91B23;
  font-weight: 700;
  font-size: 14px;
  margin-bottom: 6px;
}

.registration-form-wrapper p,
.registration-form-wrapper li {
  font-size: 16px;
  line-height: 20px;
  color: black;
}

.registration-form-wrapper label a {
  color: #C91B23;
}

.registration-form-wrapper input::placeholder {
  font-style: italic;
}

.registration-form-wrapper .form-bottom {
  margin-bottom: 24px;
}

.registration-form-wrapper .form-bottom p.heading {
  color: #C91B23;
  font-size: 19px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.registration-form-wrapper .form-bottom p.heading span {
  font-size: 15px;
  font-weight: normal;
}

.registration-form-wrapper .form-bottom .radio-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 10px;
}

@media (max-width: 550px) {
  .registration-form-wrapper .form-bottom .radio-wrapper {
    grid-template-columns: 1fr 1fr;
  }
}

.registration-form-wrapper .form-bottom .radio-wrapper input {
  display: none;
}

.registration-form-wrapper .form-bottom .radio-wrapper .radio-label {
  display: inline-flex;
  min-height: 60px;
  color: #C91B23;
  border: 1px solid #C91B23;
  border-radius: 6px;
  width: 100%;
  text-align: center;
  font-size: 15px;
  line-height: 1.1;
  padding: 12px 20px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.registration-form-wrapper .form-bottom .radio-wrapper .radio-label:hover,
.registration-form-wrapper .form-bottom .radio-wrapper .radio-label:active,
.registration-form-wrapper .form-bottom .radio-wrapper .radio-label:focus,
.registration-form-wrapper .form-bottom .radio-wrapper input:checked + .radio-label {
  background-color: #C91B23;
  color: #fff;
}

.registration-welcome {
  padding: 20px;
}

.registration-welcome h2 {
  font-size: 24px;
  font-weight: 700;
  letter-spacing: -0.9px;
  margin-bottom: 20px;
  color: #C91B23;
}

.registration-welcome p {
  color: black;
}

.registration-welcome .buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 12px;
}

.modal-donation {
  max-width: 600px !important;
}

.modal-donation .v-card {
  padding: 20px;
}

.modal-donation .v-card p {
  color: black;
}

.modal-donation h2 {
  font-size: 24px;
  font-weight: 700;
  letter-spacing: -0.9px;
  margin-bottom: 20px;
  color: #C91B23;
}

.modal-donation h3 {
  font-size: 20px;
  font-weight: 700;
  letter-spacing: -0.9px;
  margin-bottom: 8px;
  color: #C91B23;
}

.modal-donation .grey-block h4 {
  font-size: 19px;
  font-weight: 700;
  letter-spacing: -0.9px;
  margin-bottom: 8px;
  color: black;
}

.modal-donation .grey-block h4:not(:first-child) {
  margin-top: 30px;
}

.grey-block {
  padding: 20px;
  background-color: #f3f3f3;
}

.signature {
  font-style: italic;
  font-size: 15px;
  position: relative;
  padding: 20px 0 0 0;
  margin-bottom: 0;
  font-weight: 700;
}

.mobile-menu {
  border-radius: 0 !important;
  padding: 10px 0 20px 0;
}

.mobile-menu a {
  color: #B40000 !important;
  font-size: 17px;
  line-height: 22px;
  font-family: 'Merriweather', serif;
  text-decoration: none;
  padding: 12px 20px 12px 70px;
  display: block;
}

.title-1-merriweather {
  font-size: 48px;
  line-height: 60px;
  color: #1B2847;
  font-family: 'Merriweather', serif;
  font-weight: 300;
  padding-bottom: 8px;
  position: relative;
}

.title-1-merriweather:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 48px;
  height: 4px;
  background-color: #1B2847;
}

.title-1-merriweather.color-green:after {
  background-color: #349C72;
}

.title-2-merriweather {
  font-size: 24px;
  line-height: 31px;
  color: #1B2847;
  font-family: 'Merriweather', serif;
  font-weight: 300;
  font-style: italic;
}

.title-2-merriweather.color-green {
  color: #349C72;
}

.text-merriweather {
  font-size: 16px;
  line-height: 20px;
  color: #1B2847;
  font-family: 'Merriweather', serif;
}

.text-tiny-merriweather {
  font-size: 14px;
  line-height: 18px;
  color: #1B2847;
  font-family: 'Merriweather', serif;
}

.text-tiny {
  font-size: 14px;
  line-height: 16px;
  color: #1B2847;
}

.btn-back {
  font-size: 14px;
  line-height: 18px;
  color: #1B2847 !important;
  font-family: 'Merriweather', serif;
  font-weight: 700;
  position: relative;
  padding-bottom: 4px;
  display: inline-block;
}

.btn-back:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  background-color: #1B2847;
}

.btn-back:hover {
  text-decoration: none;
  opacity: 0.7;
}

.book_description {
  padding-top: 20px;
  position: relative;
  width: 240px;
  max-width: 100%;
  margin-right: 24px;
  margin-bottom: 40px;
}

@media (max-width: 750px) {
  .book_description {
    width: auto;
  }
}

.book_description:hover,
.book_description:focus,
.book_description:active {
  text-decoration: none;
}

.book_description:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 48px;
  height: 4px;
  background-color: #349C72;
  transition: 0.3s ease-in-out;
}

.book_description:hover:before,
.book_description:focus:before,
.book_description:active:before {
  height: 8px;
}

.book_description p {
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 0;
  color: #1B2847;
}

.book_description .titre {
  font-size: 18px;
  line-height: 23px;
  font-family: 'Merriweather', serif;
  font-weight: 900;
  margin-bottom: 4px;
  position: relative;
  display: inline-block;
}

.book_description .titre:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -1px;
  width: 0;
  height: 2px;
  background-color: #1B2847;
  transition: 0.3s ease-in-out;
}

.sommaire-bible .page-content {
  max-width: 730px;
}

.sommaire-bible.no-panel-right {
  max-width: 710px;
}

@media (max-width: 1400px) {
  .sommaire-bible .page-content {
    max-width: 715px;
  }
}

.sommaire-bible .panel-right .panel-summary-subtitle {
  text-transform: capitalize;
  font-weight: 700;
  font-size: 15px;
  line-height: 23px;
  color: #1B2847;
  font-family: 'Merriweather', serif;
  margin-bottom: 2px;
}

.sommaire-bible .panel-right .panel-summary-title {
  margin-bottom: 16px;
  font-size: 21px;
  line-height: 25px;
  color: #1B2847;
  font-family: 'Merriweather', serif;
  font-weight: 500;
  padding-bottom: 8px;
  position: relative;
  font-style: italic;
}

.panel-right img {
  cursor: zoom-in;
}

.chapters {
  display: flex;
  flex-wrap: wrap;
}

.chapters .chapter {
  width: 48px;
  height: 48px;
  border: 1px solid #1B2847;
  font-size: 16px;
  line-height: 17px;
  font-weight: bold;
  color: #1B2847;
  margin-right: 8px;
  margin-bottom: 8px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.chapters .chapter:hover {
  background-color: #004EDE;
  color: #fff;
  text-decoration: none;
}

.panel-right .panel-wrapper {
  background-color: #F4F4F4;
  padding: 100px 48px 80px 48px;
  transition: 0.3s ease-in-out;
}

@media (max-width: 550px) {
  .panel-right .panel-wrapper {
    padding: 24px;
  }
}

.panel-right:not(.notes-panel) .panel-wrapper {
  height: 100%;
  overflow-y: auto;
}

.panel-right .panel-wrapper {
  scrollbar-width: thin; /* "auto" or "thin"  */
  scrollbar-color: #1B2847 #E6E7EB; /* scroll thumb & track */
}

.panel-right .panel-wrapper::-webkit-scrollbar {
  width: 12px; /* width of the entire scrollbar */
}

.panel-right .panel-wrapper::-webkit-scrollbar-track {
  background: #E6E7EB; /* color of the tracking area */
}

.panel-right .panel-wrapper::-webkit-scrollbar-thumb {
  background-color: #1B2847; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  border: 3px solid #E6E7EB; /* creates padding around scroll thumb */
}

.panel-right.notes-panel {
  transform: translateX(100%);
}

.panel-right.open {
  transform: translateX(0);
}

@media (min-width: 1200px) {
  .panel-right {
    width: 528px;
    right: 0;
    top: 85px;
    height: 100%;
    max-height: calc(100vh - 85px);
    position: fixed;
    z-index: 30;
  }
}

@media (max-width: 1500px) {
  .panel-right {
    width: 450px;
    //padding: 70px 30px 50px 30px;
  }
}

@media (max-width: 1200px) {
  .panel-right {
    width: 100%;
    //padding: 30px 30px 30px 30px;
    margin-top: 36px;
  }
}

.panel-right.notes-panel {
  padding: 0;
}

@media (max-width: 1200px) {
  .panel-right:not(.open) {
    position: fixed;
    top: calc(100% - 100px);
    z-index: 700;
    height: 100vh;
    margin: 0;
  }

  .panel-right.open {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 700;
    height: 100vh;
    margin: 0;
    max-width: 600px;
  }

  .panel-right.open:before {
    content: '';
    position: absolute;
    right: 100%;
    top: 0;
    width: 100vw;
    height: 100%;
    background-color: black;
    opacity: 0.3;
  }
}

.panel-right .panel-heading {
  background-color: #D0D4DB;
  padding: 26px 60px 26px 48px;
  color: #1B2847;
  font-size: 18px;
  line-height: 23px;
  font-family: 'Merriweather', serif;
  font-weight: 900;
  position: sticky;
  top: 0;
  z-index: 5;
}

@media (max-width: 500px) {
  .panel-right .panel-heading {
    padding: 16px 60px 16px 24px;
  }
}

.panel-right .panel-heading .close-panel {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 26px;
  right: 24px;
  cursor: pointer;
}

@media (max-width: 500px) {
  .panel-right .panel-heading .close-panel {
    top: 18px;
  }
}

.panel-right .panel-heading .close-panel:before,
.panel-right .panel-heading .close-panel:after {
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  width: 24px;
  height: 1px;
  background-color: #1B2847;
}

.panel-right .panel-heading .close-panel:after {
  transform: translate(-50%, -50%) rotate(-45deg);
}

.panel-right .panel-title {
  font-size: 18px;
  line-height: 23px;
  font-weight: 900;
  color: #1B2847;
  font-family: 'Merriweather', serif;
  padding-bottom: 18px;
  margin-bottom: 27px;
  border-bottom: 1px solid #1B2847;
}

.panel-right.notes-panel .panel-wrapper {
  padding: 33px 48px;
  position: relative;
  overflow-y: auto;
  height: calc(100% - 75px);
}

.panel-right.notes-panel:has(.bottom-sticky-collapse) .panel-wrapper {
  padding-bottom: 110px;
}

@media (max-width: 500px) {
  .panel-right.notes-panel .panel-wrapper {
    padding: 16px 24px;
    height: calc(100% - 55px);
  }
}

.panel-right .panel-nav {
  list-style: none;
  padding: 0;
  border-bottom: 1px solid #1B2847;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 16px;
  justify-content: space-around;
  gap: 24px;
  position: relative;
  z-index: 10;
}

.panel-right .panel-nav li {
  font-size: 16px;
  line-height: 20px;
  font-family: 'Merriweather', serif;
  font-weight: 900;
  color: #1B2847;
  padding: 0 0 19px 0;
  opacity: 0.5;
  transition: 0.3s ease-in-out;
  cursor: pointer;
  position: relative;
}

.panel-right .panel-nav li.active,
.panel-right .panel-nav li:hover {
  opacity: 1;
}

.panel-right .panel-nav li.active:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
  width: 60px;
  height: 6px;
  background-color: #1B2847;
}

.panel-right .notes-title {
  font-size: 16px;
  line-height: 20px;
  font-family: 'Merriweather', serif;
  font-weight: 700;
}

.panel-right .rubrique-name {
  font-size: 14px;
  line-height: 20px;
  font-family: 'Merriweather', serif;
  font-weight: 700;
}

.panel-right .notes-title {
  text-transform: uppercase;
}

.panel-right .panel-text {
  font-size: 14px;
  line-height: 18px;
  color: #1B2847;
}

.panel-right .panel-collapse .panel-collapse-title {
  font-size: 14px;
  line-height: 19px;
  font-weight: 900;
  font-family: 'Merriweather', serif;
  color: #1B2847;
  padding: 16px 32px;
  border-top: 1px solid #afafaf;
  cursor: pointer;
  position: relative;
  margin-bottom: 0;
}

.panel-right .panel-collapse:last-child {
  border-bottom: 1px solid #afafaf;
  margin-bottom: 24px;
}

.panel-right .panel-collapse li,
.panel-right .panel-collapse p,
.draggable-modal .content li,
.draggable-modal .content p {
  font-size: 14px;
}

cite.title {
  font-style: italic;
}

.panel-right .panel-collapse h4,
.draggable-modal .content h4{
  font-size: 20px;
}

.panel-right .panel-collapse h5,
.draggable-modal .content h5{
  font-size: 17px;
}

.panel-right .panel-collapse figure.media,
.draggable-modal .content figure.media{
  padding: 12px;
  background: #ebebeb;
}

.panel-right .panel-collapse figure.media figcaption,
.draggable-modal .content figure.media figcaption{
  text-align: center;
}

.panel-right .panel-collapse figure.media figcaption p.author,
.draggable-modal .content figure.media figcaption p.author{
  font-variant: small-caps;
}

.panel-right .panel-collapse figure.media figcaption p:first-child,
.draggable-modal .content figure.media figcaption p:first-child{
  font-weight: 700;
  margin-bottom: 8px;
}

.panel-right .panel-collapse figure.media figcaption p:not(:first-child),
.draggable-modal .content figure.media figcaption p:not(:first-child){
  font-size: 12px;
  margin-bottom: 8px;
}

.panel-collapse:first-child .panel-collapse-title {
  border-top: none;
}

.panel-right .panel-collapse .panel-collapse-title svg {
  position: absolute;
  left: 0;
  top: 16px;
}

.panel-right .panel-collapse .panel-collapse-title.collapse-open svg {
  transform: rotate(90deg);
}

.panel-right .panel-collapse .panel-collapse-content-wrapper {
  display: grid;
  grid-template-rows: 1fr;
  transition: 0.3s ease-in-out;
}

.panel-right .panel-collapse .panel-collapse-content-wrapper.hide-content {
  grid-template-rows: 0fr;
}

.panel-right .panel-collapse .panel-collapse-content {
  overflow: hidden;
  font-size: 14px;
  line-height: 20px;
}

.panel-right .bottom-sticky-collapse {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  box-shadow: 1px -4px 6px #00000029;
  padding: 13px 24px 14px 24px;
}

.panel-right .bottom-sticky-collapse p,
.panel-right .bottom-sticky-collapse p span,
.panel-right .bottom-sticky-collapse p a {
  margin-bottom: 0;
  font-family: 'Merriweather', serif !important;
  font-size: 11px;
  line-height: 14px;
  font-weight: bold;
}

.panel-right .bottom-sticky-collapse p a.xref {
  color: #C91B23;
}

.panel-text-wrapper p {
  font-size: 14px;
}

.content-left {
  transition: 0.3s ease-in-out;
  max-width: 100%;
}

.content-left.panel-open {
  max-width: calc(100vw - 543px);
}

.content-left .content-bible {
  scrollbar-width: thin; /* "auto" or "thin"  */
  scrollbar-color: #1B2847 #E6E7EB; /* scroll thumb & track */
}

.content-left .content-bible::-webkit-scrollbar {
  width: 12px; /* width of the entire scrollbar */
}

.content-left .content-bible::-webkit-scrollbar-track {
  background: #E6E7EB; /* color of the tracking area */
}

.content-left .content-bible::-webkit-scrollbar-thumb {
  background-color: #1B2847; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  border: 3px solid #E6E7EB; /* creates padding around scroll thumb */
}

.content-left .content-bible footer {
  padding-right: inherit !important;
  width: 100vw;
  position: relative;
  margin-left: -50vw;
  left: 50%;
  transform: translateY(27px);
}

@media (min-width: 1500px) {
  .content-left.panel-open .content-bible footer {
    width: calc(100vw - 554px);
    margin-left: calc(-50vw + 277px);
  }
}

@media (max-width: 1500px) {
  .content-left.panel-open .content-bible footer {
    width: calc(100vw - 476px);
    margin-left: calc(-50vw + 238px);
  }
}

@media (max-width: 1500px) {
  .content-left.panel-open {
    max-width: calc(100vw - 465px);
  }
}

@media (min-width: 1200px) {
  .content-left.panel-open .top-navigation {
    top: 0;
  }

  .content-left.panel-open .content-bible {
    height: calc(100vh - 72px - 83px);
    overflow-y: auto;
  }
}

@media (max-width: 1450px) {
  .content-left.panel-open .verse {
    padding-right: 36px;
  }
}

@media (max-width: 1200px) {
  .content-left.panel-open {
    max-width: 100%;
  }
}

.shepherd-content .shepherd-button.bordered {
  border: 1px solid;
}

.shepherd-element.one-button .shepherd-content .shepherd-footer {
  justify-content: center;
}

.btn-red {
  padding: 5px 16px !important;
  color: #C91B23 !important;
  border: 1px solid;
  font-size: 14px !important;
  line-height: 18px;
  font-weight: 700;
  font-family: 'Merriweather', serif !important;
  border-radius: 4px;
  background-color: transparent !important;
  box-shadow: none;
  text-transform: inherit;
  height: auto !important;
  min-height: 50px;
  display: inline-flex;
  align-items: center;
}

.btn-red.full {
  background-color: #C91B23 !important;
  color: white !important;
}

.btn-red.full:hover,
.btn-red.full:active,
.btn-red.full:focus {
  background-color: #a9131b !important;
}

.btn-red.full svg {
  stroke: white;
}

.btn-red span {
  font-size: 14px !important;
  line-height: 18px;
  font-weight: 700;
  font-family: 'Merriweather', serif !important;
  letter-spacing: 0.4px !important;
}

.btn-red.active,
.btn-red:hover,
.btn-red:active,
.btn-red:focus{
  background-color: #C91B23 !important;
  color: white !important;
  text-decoration: none;
}

.btn-red.active svg,
.btn-red:hover svg,
.btn-red:active svg,
.btn-red:focus svg {
  stroke: white;
}

.btn-box {
  padding: 13px 7px;
  height: 50px;
  min-width: 48px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #D0D4DB;
  border-radius: 4px;
  color: #545454 !important;
}

@media (max-width: 520px) {
  .btn-box {
    padding: 11px 4px;
    height: 36px;
    min-width: 36px;
  }
}

.btn-box:hover,
.btn-box:active,
.btn-box:focus {
  background: #004EDE;
  border-color: #004EDE;
  color: white !important;
  cursor: pointer;
  text-decoration: none;
}

.btn-box:hover svg,
.btn-box:active svg,
.btn-box:focus svg {
  stroke: white;
}

.tradition-badge {
  font-size: 10px !important;
  line-height: 13px !important;
  font-family: 'Merriweather', serif;
  font-weight: 900;
  background-color: var(--full-color-badge, #D0D4DB);
  border-radius: 2px;
  min-width: 24px;
  height: 24px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: var(--color-text-badge, #5A637A);
}

.tradition-badge.badge-color-hover:hover {
  background-color: var(--color-badge, #D0D4DB);
  color: white;
}

.tradition-badge span {
  font-size: inherit;
  line-height: inherit;
}

@media (min-width: 900px) {
  .verse .tradition-badge {
    min-width: 16px;
    height: 16px;
    display: inline-flex;
    padding: 2px 4px;
    font-size: 10px;
    line-height: 14px;
    opacity: 0;
    transition: 0.3s ease-in-out;
    vertical-align: middle;
    transform: translateY(-1px);
  }
}

.verse .tradition-wrapper > .traditions {
  max-width: 40px;
}

.verse .tradition-wrapper .witness .verse_part,
.verse .tradition-wrapper .witness .verse_part span {
  font-size: 14px;
  line-height: 20px;
}

.verse .tradition-wrapper .witness .verse_part,
.verse .tradition-wrapper .witness .verse_part span {
  font-size: 14px;
  line-height: 20px;
}

.verse .tradition-wrapper > .traditions > .tradition-badge {
  transform: translateY(-1px);
}

.verse:hover .tradition-badge,
.verse.verse-note-open .tradition-badge,
.verse.active-notes .tradition-badge {
  opacity: 1;
}

.verse:hover .verse_part.contains-variant,
.verse.verse-note-open .verse_part.contains-variant,
.verse.active-notes .verse_part.contains-variant {
  margin-bottom: 6px !important;
  /*display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;*/
}

/*.verse:hover .verse_part,
.verse.verse-note-open .verse_part,
.verse.active-notes .verse_part {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}*/

.verse .verse_part .text-with-variant {
  position: relative;
  display: inline-block;
}

.verse:hover .verse_part .text-with-variant:has(.text-variant),
.verse.verse-note-open .verse_part .text-with-variant:has(.text-variant),
.verse.active-notes .verse_part .text-with-variant:has(.text-variant) {
  margin-top: -10px;
  transform: translateY(10px);
  margin-bottom: 10px !important;
  margin-inline: 1px;
}

.verse .verse_part .text-with-variant .text-variant span {
  font-size: 14px;
  line-height: 18px;
}

.verse .verse_part .text-with-variant .tmr-1 {
  margin-right: 3px;
}

.verse .verse_part .text-with-variant .tmr-1 .tradition-badge:not(:last-child) {
  margin-right: 2px;
}

.verse .verse_part .text-with-variant .base-text {
  border-radius: 3px;
}

@media (min-width: 900px) {
  .verse .verse_part .text-with-variant .elipsis-text:not(.show-elipsis) {
    display: none;
  }

}

/*.verse:hover .verse_part.contains-variant,
.verse.active-notes .verse_part.contains-variant {
  margin-bottom: 30px !important;
}*/

.verse:hover .verse_part .text-with-variant .base-text,
.verse.verse-note-open .verse_part .text-with-variant .base-text,
.verse.active-notes .verse_part .text-with-variant .base-text {
  border: 1px solid #949FBA;
}

.verse:hover .verse_part .text-with-variant .elipsis-text,
.verse.verse-note-open .verse_part .text-with-variant .elipsis-text,
.verse.active-notes .verse_part .text-with-variant .elipsis-text {
  display: inline-flex;
}

.text-with-variant .text-variant {
  display: none;
  background-color: white;
  border-radius: 0 3px 3px 3px;
  padding: 5px 8px 5px 5px;
  z-index: 8;
  box-shadow: 0 3px 6px -2px #b2b2b2;
  max-width: 450px;
  width: max-content;
  position: relative;
  transition: 0.3s ease-in-out;
  margin-bottom: 6px !important;
  position: static;
}

.text-with-variant .text-variant .text {
  font-size: 14px;
  line-height: 18px;
  font-family: 'Merriweather', serif;
  font-style: italic;
  color: #5A637A;
}

.verse:hover .verse_part .text-with-variant .base-text,
.verse.verse-note-open .verse_part .text-with-variant .base-text,
.verse.active-notes .verse_part .text-with-variant .base-text {
  background-color: white;
  border-color: white;
  border-radius: 3px 3px 0 0;
  box-shadow: 0 0 8px -2px #b2b2b2;
}

.verse.verse-note-open .verse_part .text-with-variant .base-text .tradition-badge,
.verse.active-notes .verse_part .text-with-variant .base-text .tradition-badge {
  margin-left: 3px;
}

.verse:hover .verse_part .text-with-variant .text-variant,
.verse.verse-note-open .verse_part .text-with-variant .text-variant,
.verse.active-notes .verse_part .text-with-variant .text-variant {
  display: flex;
}

.verse:hover .verse_part .text-with-variant .text-variant .tmr-1:not(:first-child),
.verse.verse-note-open .verse_part .text-with-variant .text-variant .tmr-1:not(:first-child),
.verse.active-notes .verse_part .text-with-variant .text-variant .tmr-1:not(:first-child) {
  margin-left: 6px;
}

.switcher input {
  display: none;
}

.switcher label {
  font-size: 14px;
  line-height: 18px;
  color: #1B2847;
  font-family: 'Merriweather', serif;
  display: grid;
  grid-template-columns: 1fr 40px;
  align-items: center;
  grid-gap: 26px;
}

.switcher label div {
  font-size: 14px;
  line-height: 18px;
  color: #1B2847;
  font-family: 'Merriweather', serif;
}

.switcher .slider {
  display: inline-block;
  width: 40px;
  height: 24px;
  position: relative;
  background-color: #ccc;
  transition: 0.3s;
  border-radius: 34px;
  cursor: pointer;
}

.switcher .slider:before {
  content: '';
  width: 18px;
  height: 18px;
  background-color: white;
  transition: 0.3s;
  border-radius: 50px;
  left: 3px;
  top: 3px;
  position: absolute;
}

.switcher input:checked + label .slider {
  background-color: #38BE87;
}

.switcher input:checked + label .slider.disabled {
  background-color: #97c7b3;
  cursor: default;
}

.switcher input:checked + label .slider:before {
  left: 19px;
}

.custom-radio input {
  display: none;
}

.custom-radio label {
  font-size: 14px;
  line-height: 18px;
  color: #1B2847;
  font-family: 'Merriweather', serif;
  display: grid;
  grid-template-columns: 22px 1fr;
  align-items: center;
  grid-gap: 8px;
}

.custom-radio .radio-toggle {
  width: 22px;
  height: 22px;
  border-radius: 50px;
  background-color: white;
  border: 2px solid #A7ADB7;
  position: relative;
  cursor: pointer;
}

.custom-radio .radio-toggle:before {
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% - 6px);
  height: calc(100% - 6px);
  background-color: white;
  border-radius: 50px;
}

.custom-radio input:checked + label .radio-toggle {
  border-color: #38BE87;
}

.custom-radio input:checked + label .radio-toggle:before {
  background-color: #38BE87;
}

.loader {
  position: relative;
  width: 100px;
  height: 100px;
  margin: 16px auto;
}

.loader:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.73);
  z-index: 5;
}

.loader:after {
  content: '';
  position: absolute;
  left: calc(50% - 30px);
  top: calc(50% - 30px);
  width: 60px;
  height: 60px;
  border: 5px solid #B40000;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  z-index: 6;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.verse .other-traditions {
  display: grid;
  transition: 0.3s ease-in-out;
  font-size: 14px;
  line-height: 21px;
  overflow: hidden;
}

@media (min-width: 900px) {
  .verse .other-traditions {
    color: #5A637A;
    font-style: italic;
  }

  .verse .other-traditions {
    grid-template-rows: 0fr;
  }

  .verse:hover .other-traditions,
  .verse.verse-note-open .other-traditions,
  .verse.active-notes .other-traditions {
    grid-template-rows: 1fr;
  }

  .verse .other-traditions .other-traditions-content {
    overflow: hidden;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 8px;
    transition: 0.3s ease-in-out;
    border-radius: 4px;
    padding: 0 8px;
    border: 1px solid transparent;
  }

  .verse:hover .other-traditions .other-traditions-content,
  .verse.verse-note-open .other-traditions .other-traditions-content,
  .verse.active-notes .other-traditions .other-traditions-content {
    margin-top: 8px;
    padding: 8px;
    border: 1px solid #D0D4DB;
    background-color: white;
  }
}

.verse .other-traditions .other-traditions-content .tradition-wrapper {
  display: flex;
}

@media (max-width: 900px) {
  .verse .other-traditions .other-traditions-content .tradition-wrapper {
    padding-top: 24px;
  }
}

.author{
  font-variant: small-caps;
}

.note-content .media {
  flex-direction: column;
  align-items: center;
}

.note-content img,
.note-content iframe,
.note-content video {
  max-width: 100%;
  margin-bottom: 16px;
}

.note-content li .title,
.note-content p .title {
  font-size: inherit !important;
  font-weight: inherit !important;
  letter-spacing: inherit !important;
  line-height: inherit !important;
  font-family: inherit !important;
}

.custom-modal {
  box-shadow: 0 8px 32px #000000A8;
  border: 1px solid #949FBA;
  border-radius: 8px;
}

.custom-modal,
.custom-modal * {
  touch-action: auto !important;
}

.custom-modal .header {
  display: flex;
  justify-content: space-between;
  padding: 24px 29px 24px 44px;
  border-bottom: 1px solid #b2b2b2;
}

.custom-modal .header .modal-title {
  font-family: 'Merriweather', serif;
  font-size: 18px;
  line-height: 23px;
  color: #1B2847;
  font-weight: 700;
  display: flex;
  align-items: center;
}

.custom-modal .header svg {
  cursor: pointer;
  margin-left: 10px;
}

.custom-modal .content .verse {
  display: block;
  padding: 10px 20px;
}

.custom-modal .content .verse .traditions {
  justify-content: flex-start !important;
}

.modal-verse-link {
  background-color: #f4f4f4;
  padding: 16px 20px;
}

.modal-verse-link a {
  font-family: 'Merriweather', serif;
  font-size: 18px;
  line-height: 23px;
  color: #B40000 !important;
  font-weight: 700;
  text-decoration: underline;
}

.custom-modal .content .text-content {
  padding: 20px 45px;
}

.custom-modal .content .text-content .media {
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-modal .content .text-content .media img {
  max-width: 100%;
}

.custom-modal .modal-resizer {
  position: absolute;
  right: 0;
  bottom : 0;
  width: 20px;
  height: 20px;
  background: red;
}

.custom-modal .text-content figure.media {
  padding: 24px;
  background: #f5f5f5;
}

.custom-modal .text-content figure.media img {
  max-width: 100%;
  margin: 0 0 16px 0;
}

.custom-modal .text-content figure.media figcaption {
  text-align: center;
  font-size: 90%;
}

.custom-modal .text-content figure.media figcaption p.author {
  font-variant: small-caps;
}

.custom-modal .text-content figure.media figcaption .title {
  font-size: 16px;
}

.custom-modal .text-content figure.media figcaption p:first-child {
  font-weight: 700;
  margin-bottom: 8px;
}

.custom-modal .text-content figure.media figcaption p:not(:first-child) {
  font-size: 12px;
  margin-bottom: 8px;
}



/* Dark theme adjustments */
.theme--dark .title-1-merriweather,
.theme--dark .title-2-merriweather,
.theme--dark .book_description p,
.theme--dark .btn-back,
.theme--dark .text-merriweather,
.theme--dark .text-tiny-merriweather,
.theme--dark .chapters .chapter {
  color: white;
}

.theme--dark .chapters .chapter {
  border-color: white;
}

.theme--dark .panel-wrapper,
.theme--dark .verse:hover,
.theme--dark .verse.verse-note-open,
.theme--dark .verse.active-notes,
.theme--dark .verse.active,
.theme--dark .chapter-select-container .text-merriweather {
  color: #272727;
}

.testament-search .v-slide-group__wrapper {
  max-width: 800px;
  margin: 0 auto;
}

.testament-search .v-window__container {
  max-width: 864px;
  margin: 0 auto;
}

.text-search-results .v-window__container {
  max-width: 900px;
  margin: 0 auto;
}

.text-search-results .v-window__container .result .cut-result {
  white-space: unset;
}

.alphabet-letter-nav {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  padding-bottom: 36px;
  position: sticky;
  top: -15px;
  background: #f4f4f4;
  z-index: 5;
}

.alphabet-letter-nav:before {
  content: '';
  width: 100%;
  height: 36px;
  background: #f4f4f4;
  position: absolute;
  bottom: 100%;
  left: 0;
}

.alphabet-letter-nav > div {
  display: flex;
  width: 24px;
  height: 24px;
  cursor: pointer;
  background: #e2e2e2;
  align-items: center;
  justify-content: center;
}

.alphabet-letter-nav > div:hover {
  background-color: #004EDE;
  color: white;
}

.panel-right .alphabet-letter {
  color: #B40000;
  font-size: 18px;
}

.customModalRecherche {
  display: flex;
  align-items: center;
  justify-content : center;
}

.customModalRecherche > div {
  max-width: 90vw;
  min-width: 50vw;
}

.customModalRecherche .v-tabs-items::-webkit-scrollbar {
  width: 10px;
}

.customModalRecherche .v-tabs-items::-webkit-scrollbar-track {
  background: #D0D4DB;
}

.customModalRecherche .v-tabs-items::-webkit-scrollbar-thumb {
  background: #C91B23;
  cursor: pointer;
  border-radius: 5px;
}

.customModalRecherche .v-tabs-items::-webkit-scrollbar-thumb {
  background: #a9131b;
}

.customModalRecherche .v-tabs-items {
  height: 70vh;
  overflow: auto;
}

.customModalRecherche .v-list-item {
  min-height: unset;
  margin-bottom: 15px;
}

.customModalRecherche .v-list-item .font-weight-black{
  background-color: #6a99ef;
}

.customModalRecherche .v-list-item>div,
.customModalRecherche .v-list-item>a:not(.book) {
  width: 100%;
}

.customModalRecherche .v-slide-group__content {
  justify-content: center;
}

.customModalRecherche .text-search-results .v-list-item > .xref,
.customModalRecherche .text-search-results .v-list-item > .note_xref,
.customModalRecherche .text-search-results .v-list-item > .synth_link,
.customModalRecherche .text-search-results .v-list-item > .verse {
  padding: 8px;
  border-radius: 6px;
  text-decoration: none;
  margin-bottom: 3px;
  transition: 0.3s ease-in-out;
  transform: translateX(-8px);
  display: block;
  cursor: pointer;
}
.customModalRecherche .text-search-results .v-list-item > .xref:hover,
.customModalRecherche .text-search-results .v-list-item > .note_xref:hover,
.customModalRecherche .text-search-results .v-list-item > .synth_link:hover,
.customModalRecherche .text-search-results .v-list-item > .verse:hover {
  background-color: #E5ECF5;
  transform: translateX(0);
}

.customModalRecherche .text-search-results .result .title {
  font-size: inherit !important;
}

.customModalRecherche .text-search-results .result p:last-child {
  margin-bottom: 0;
}

.customModalRecherche .margref-item {
  margin-bottom: 10px;
}

.search.search-no-access {
  cursor: not-allowed;
  position: relative;
}

.search.search-no-access * {
  cursor: not-allowed;
}

.search.search-no-access input {
  pointer-events: none;
}

.search.search-no-access .no-access-popin {
  position: absolute;
  left: 0;
  top: calc(100% + 8px);
  padding-top: 10px;
  transition: 0.4s;
  opacity: 0;
  visibility: hidden;
  width: 270px;
}

.search.search-no-access:hover .no-access-popin,
.no-access-popin:hover {
  opacity: 1;
  visibility: visible;
  top: 100%;
}

.search.search-no-access .no-access-popin .popin {
  background: white;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0 0 9px -2px #c2c2c2;
  position: relative;
  cursor: auto;
}

.search.search-no-access .no-access-popin .popin:before {
  content: '';
  display : inline-block;
  height : 0;
  width : 0;
  border-right : 10px solid transparent;
  border-bottom : 10px solid white;
  border-left : 10px solid transparent;
  position: absolute;
  bottom: 100%;
  left: 50px;
  filter: drop-shadow(0px -3px 6px #c2c2c2);
}
.search.search-no-access .no-access-popin .popin:after {
  content: '';
  position: absolute;
  top: 0;
  left: 50px;
  width: 20px;
  height: 5px;
  background: white;
}

.search.search-no-access .no-access-popin .popin p {
  margin: 0;
  font-size: 15px;
  line-height: 19px;
  cursor: auto;
}

.search.search-no-access .no-access-popin .popin p a {
  color: #B40000;
  cursor: pointer;
}

.v-toolbar.v-app-bar {
  z-index: 600;
}

.loader-wrapper {
  min-height: calc(100vh - 250px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.lightbox-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0,0,0,0.8);
  text-align: center;
  padding: 50px 24px;
  z-index: 9999;
  overflow: auto;
}

.lightbox-wrapper .close-lightbox {
  position: absolute;
  right: 12px;
  top: 12px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.lightbox-wrapper img {
  max-width: 100%;
}

.lightbox-wrapper .alt {
  text-align: center;
  font-style: italic;
  color: white;
  font-size: 18px;
  margin: 12px auto 0 auto;
  max-width: 800px;
}

.bible-content-wrapper:not(.chapter-panel-open) .tradition-configuration-wrapper:not(.traditions) {
  display: none;
}

.bible-content-wrapper.chapter-panel-open .tradition-configuration-wrapper:not(.traditions) {
  display: block !important;
}

.iframe-media, .iframe-audio {
  container-type: inline-size;
  position: relative;
}

.iframe-media iframe, .iframe-audio iframe {
  position: relative;
  display: block;
  margin: 0;
}

.iframe-media iframe {
  height: 400px;
}

.iframe-audio iframe {
  height: 120px;
}

@container (max-width: 500px) {
  .iframe-media iframe {
    height: 300px;
  }
}

@container (max-width: 400px) {
  .iframe-media iframe {
    height: 250px;
  }
}

@container (max-width: 350px) {
  .iframe-media iframe {
    height: 200px;
  }
}

@container (max-width: 350px) {
  .iframe-media iframe {
    height: 200px;
  }
}

.desktop-only {
  @media (max-width: 699px) {
    display: none;
  }
}

.mobile-only {
  @media (min-width: 700px) {
    display: none;
  }
}

.custom-mobile-menu {
  display: flex;
  align-items: center;
}

@media (max-width: 900px) {
  .custom-mobile-menu {
    position: absolute;
    right: 0;
    top: 100%;
    background: white;
    width: 100%;
    padding: 24px 8px;
    justify-content: flex-end;
    box-shadow: 0 3px 8px 0 #0000001f;
    flex-wrap: wrap;
    gap: 10px 0;
  }

  .custom-mobile-menu:not(.show) {
    display: none;
  }
}

.open-mobile-menu {
  cursor: pointer;
}

@media (min-width: 899px) {
  .open-mobile-menu {
    display: none;
  }
}

.text-red {
  color: #C91B23 !important;
}

.text-green {
  color: #349c72 !important;
}

.v-dialog__content.v-dialog__content--active {
  z-index: 2002 !important;
  background: #00000042 !important;
}

.flying-cta {
  position: fixed;
  bottom: 24px;
  right: 24px;
  width: 50px;
  height: 50px;
  background: #101e38;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  box-shadow: 0 0 5px 1px #7f7f7f;
  transition: 0.3s ease-in-out;
}

.flying-cta:hover,
.flying-cta:active,
.flying-cta:focus {
  background: #273b66;
}

body.panel-open {
  .flying-cta {
    @media (min-width: 1200px) {
      right: 474px;
    }

    @media (min-width: 1500px) {
      right: 552px;
    }
  }
}

</style>