<template>
  <v-menu v-model="menu" :close-on-content-click="false" offset-x>
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" class="login-btn btn-red mr-3">{{ token ? $t('my account') : $t('log in') }}</v-btn>
    </template>
    <template>
      <v-tabs class="elevation-2 panel-login">
        <v-tabs-slider></v-tabs-slider>
        <v-tab href="#tab-login" class="tab-element" v-if="!token">
          {{ $t('log in') }}
        </v-tab>
        <v-tab href="#tab-account" class="tab-element" v-if="token">
          {{ $t('my account') }}
        </v-tab>
        <v-tab-item value="tab-login" v-if="!token">
          <v-card flat tile>
            <v-card-text class="login-form-wrapper">
              <div class="close-modal close-login-modal"><svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-x" width="28" height="28" viewBox="0 0 24 24" stroke-width="1.5" stroke="#000000" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M18 6l-12 12" /><path d="M6 6l12 12" /></svg></div>
              <h1>Connexion</h1>
              <div class="form-group">
                <label for="login">{{ $t('login') }}</label>
                <input id="login" name="_username" :placeholder="$t('your email')" class="form-control" v-model="username" />
              </div>
              <div class="form-group">
                <label for="password">{{ $t('password') }}</label>
                <input id="password" name="_password" :placeholder="$t('your password')" type="password" class="form-control" v-model="password" />
              </div>
              <p class="text-red" v-if="error">{{ error }}</p>
              <a :href="Routing.generate('front_security_forgot_password')" target="_blank" class="btn-red mr-3">{{ $t('forgotten password') }}</a>
              <button @click="login" class="btn-red full">{{ $t('log in') }}</button>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item value="tab-account" v-if="token">
          <v-card flat tile v-if="!show_update_account">
            <v-card-text>
              <div class="close-modal close-login-modal"><svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-x" width="28" height="28" viewBox="0 0 24 24" stroke-width="1.5" stroke="#000000" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M18 6l-12 12" /><path d="M6 6l12 12" /></svg></div>
              <div class="registration-welcome">
                <div>
                  <h2>{{ $t('my account') }}</h2>
                  <p><strong>{{ $t('name') }}</strong> : {{ user.fullname }}</p>
                  <p><strong>{{ $t('email') }}</strong> : {{ user.email }}</p>
                  <p><strong>{{ $t('access') }}</strong> : {{ user.roles && user.roles.includes('ROLE_PAYING') ? $t('access_paying') : $t('access_login') }}</p>
                  <div v-html="user.roles && user.roles.includes('ROLE_PAYING') ? $t('access_paying_description') : $t('access_login_description')"></div>
                </div>
                <div class="buttons">
                  <button @click="logout" class="btn-red">{{ $t('log out') }}</button>
                  <button @click="showUpdateAccount(true)" class="btn-red" v-if="user">{{ $t('update_account') }}</button>
                  <a class="btn-red full" href="https://don.fondationdesmonasteres.org/soutenir-Bible-en-ses-Traditions" target="_blank">{{ $t('make_donation') }}</a>
                </div>
              </div>
            </v-card-text>
          </v-card>
          <v-card flat tile v-else-if="user">
            <v-card-text>
              <UpdateAccount :user="user" />
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </template>
  </v-menu>
</template>

<script>
import BestApiService from '../services/BestApiService.js';
import Routing from '../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js';
import UpdateAccount from "./UpdateAccount.vue";

export default {
  components: {UpdateAccount},
  data () {
    return {
      error: '',
      menu: false,
      show_update_account: false,
      password: '',
      username: '',
      Routing,
    }
  },
  mounted() {
    this.show_update_account = false;
    window.$vueEventBus.$on('hideUpdateAccount', () => {
      this.show_update_account = false;
    })
  },
  methods: {
    login: function (event) {
      event.preventDefault();
      if (!this.username || !this.password) {
        return;
      }
      BestApiService.fetchToken(this.username, this.password)
      .then(json => {
        if (json.token) {
          this.$store.commit('SET_TOKEN', json.token);
          this.menu = false;

          BestApiService.fetchUser()
              .then(json => {
                if (json.status == 'success') {
                  let user = json.user;
                  this.$store.commit('SET_USER', user);
                  this.$store.commit('SET_API_TOKEN', json.token_content);
                  this.firstname = user.firstname
                  this.lastname = user.lastname
                  this.email = user.email
                }
              });
        } else {
          this.error = this.$t('bad credentials');
        }
      })
      .catch((error) => {
        this.error = this.$t('bad credentials');
      })
    },
    showUpdateAccount(wantShow) {
      this.show_update_account = wantShow
    },
    logout() {
      this.$store.commit('SET_TOKEN', '');
      this.$store.commit('SET_USER', null);
      this.$store.commit('SET_API_TOKEN', '');
      this.$store.dispatch('resetActiveTraditions')
      this.menu = false;
    },
  },
  props: {
    user: {
      type: Object,
      default: () => {
        return {}
      }
    },
    token: {
      type: String,
      default: () => {
        return ''
      }
    },
  },
  name: 'Login',
}
</script>

<style scoped>

  h1 {
    font-weight: 700;
    font-family: 'Merriweather', serif;
    margin-bottom: 30px;
    font-size: 24px;
    font-weight: 700;
    color: #C91B23;
  }

  label {
    color: black;
    font-weight: bold;
  }

  .btn-primary {
    min-width: 160px;
    font-weight: bold;
  }

  .close-modal {
    position: absolute;
    right: 16px;
    top: 16px;
    cursor: pointer;
  }
</style>